import {useAppSelector} from "../app/hooks";
import {RootState} from "../app/store";
import {useEffect, useState} from "react";
import './SurveyReport.css'
import {IonCol, IonGrid, IonIcon, IonImg, IonRow} from "@ionic/react";
import {trendingDownOutline, trendingUpOutline} from "ionicons/icons";
import {findPreviousFlightEvaluation} from "../utils/utils";
import {FlightEvaluation} from "../model";
import {SurveyChart} from "./charts/SurveyChart";
import CountUp from 'react-countup';
import {useTranslation} from "react-i18next";

export const SurveyReport = () => {
    const flight = useAppSelector((state: RootState) => state.flight);

    const [total, setTotal] = useState<number>(0);
    const [diff, setDiff] = useState<number>(0);
    const [weaknesses, setWeaknesses] = useState<string[]>([]);
    const {t} = useTranslation(['pages.flights', 'common']);

    useEffect(() => {
        if (flight.current) {
            //const evaluation: Evaluation = flight.current.evaluation;
            //const t: number = evaluation.manager + evaluation.self + evaluation.bonus;
            setTotal(flight.current.totalScore);
            const previousFlightEvaluation: FlightEvaluation | undefined = findPreviousFlightEvaluation(flight.current, flight.previous);
            if (previousFlightEvaluation) {
                setDiff(flight.current.totalScore - previousFlightEvaluation.totalScore);
            }
        }
    }, [])

    useEffect(() => {
        if (flight.currentFlightWeakness) {
            setWeaknesses(flight.currentFlightWeakness.questions);
        } else {
            setWeaknesses([]);
        }
    }, [flight.currentFlightWeakness])

    const computeDiffBlock = () => {
        if (diff === 0) {
            return <div className={'block-line'}>
                <div className={'diff'}>+0</div>
                <div className={'previous'}>{t('surveyReport.likePrevious')}</div>
            </div>
        }
        if (diff < 0) {
            return <div className={'block-line'}>
                <div className={'diff neg'}>
                    <IonIcon icon={trendingDownOutline}/>
                    &nbsp;
                    <CountUp end={diff} duration={1}/>
                </div>
                <div className={'previous'}>{t('surveyReport.sincePrevious')}</div>
            </div>
        }
        return <div className={'block-line'}>
            <div className={'diff pos'}>
                <IonIcon icon={trendingUpOutline}/>
                &nbsp;
                <CountUp end={diff} prefix={'+'} duration={1}/>

            </div>
            <div className={'previous'}>{t('surveyReport.sincePrevious')}</div>
        </div>
    }

    const computeDiffRewardBlock = () => {
        if (diff >= 200) {
            return <div>
                <IonImg src={'assets/images/reward-trophy.png'}/>
            </div>
        }
        if (diff >= 100) {
            return <div>
                <IonImg src={'assets/images/reward-medal.png'}/>
            </div>
        }
        if (diff >= 50) {
            return <div>
                <IonImg src={'assets/images/reward-star.png'}/>
            </div>
        }

        return <div/>
    }

    const computeWeaknessesBlock = () => {
        if(weaknesses.length === 0){
            return <div/>
        }

        return <>
            <IonRow className={'no-padding'}>
                <IonCol className={'no-padding'}>
                    <div className={'centered'}>
                        <div>
                            <div  className={'weakness-message'}>
                                {t('surveyReport.weaknessMessage')}
                            </div>
                            <br/>
                            <div className={'padded-10'}>
                                <ul>
                                    {weaknesses.map((w, i) => {
                                        return <li className={"weakness-item"} key={i}>{w}</li>
                                    })}
                                </ul>
                            </div>
                        </div>
                    </div>
                </IonCol>
            </IonRow>
        </>
    }

    return <IonGrid>
        <IonRow className={'no-padding'}>
            <IonCol className={'no-padding'}>
                <div className={'reward-block centered'}>
                    {computeDiffRewardBlock()}
                </div>

            </IonCol>
        </IonRow>
        <IonRow className={'no-padding'}>
            <IonCol className={'no-padding'}>
                <div className={'score-block'}>
                    <div className={'block-line'}>
                        <CountUp end={total} duration={1}/>
                        &nbsp; points
                    </div>
                </div>
                <div className={'diff-block'}>{computeDiffBlock()}</div>

            </IonCol>
        </IonRow>
        <IonRow className={'no-padding'}>
            <IonCol className={'no-padding'}>
                <SurveyChart/>
            </IonCol>
        </IonRow>
        {computeWeaknessesBlock()}
    </IonGrid>

}
