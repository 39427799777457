import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {getPreferences, setPreferences} from "../../services/storage.service";

//import {callRefreshAccessToken} from "../../helpers/surveyApiHelper";
const KEY: string = 'survey';

export interface surveyState {
    surveysFilledAsPnc: string[]
}

const initialState: surveyState = {
    surveysFilledAsPnc: []
}

export const loadStoredPreferences = createAsyncThunk(
    'survey/loadStoredPreferences',
    async () => {
        return await getPreferences<surveyState>(KEY, initialState)
            .then((result) => {
                return result;
            });
    }
)

export const surveySlice = createSlice({
    name: 'survey',
    initialState,
    reducers: {
        onLoadPreferences: (state, action: PayloadAction<surveyState>) => {
            const storedState: surveyState = action.payload;
            state.surveysFilledAsPnc = storedState.surveysFilledAsPnc;
        },
        onPncFillSurvey: (state, action: PayloadAction<string>) => {
            state.surveysFilledAsPnc.push(action.payload);
            setPreferences(KEY, state).then().catch();
        }
    },
    extraReducers: (builder) => {
        // Add reducers for additional action types here, and handle loading state as needed
        builder
            .addCase(loadStoredPreferences.fulfilled, () => {
            })
    }
})

export const surveyActions = surveySlice.actions

export default surveySlice.reducer
