import {IonContent, IonIcon, IonLabel, IonRouterOutlet, IonTabBar, IonTabButton, IonTabs} from "@ionic/react";
import {Redirect, Route} from "react-router-dom";
import {FlightScreen} from "../pages/FlightScreen";
import Points from "../pages/Points";
import Trainings from "../pages/Trainings";
import {airplaneOutline, barChartOutline, libraryOutline} from "ionicons/icons";
import {IonReactRouter} from "@ionic/react-router";
import React, {useEffect, useState} from "react";
import {useAppSelector} from "../app/hooks";
import {RootState} from "../app/store";
import {LoginScreen} from "./login/LoginScreen";
import './AppContent.css';
import {CustomSpinner} from "./CustomSpinner";
import {isCcp, shouldDisplayBottomToolBar} from "../utils/utils";
import {useTranslation} from "react-i18next";

export const AppContent = () => {
    const user = useAppSelector((state: RootState) => state.user);
    const auth = useAppSelector((state: RootState) => state.auth);
    const [smoothEntry, setSmoothEntry] = useState(true);
    const {t} = useTranslation(['common']);

    useEffect(() => {
    }, [auth, user]);

    useEffect(() => {
        setTimeout(() => {
            setSmoothEntry(false)
        }, 500);
    }, []);

    /*
                        <IonTabButton tab="trainings" href="/trainings">
                        <IonIcon aria-hidden="true" icon={libraryOutline}/>
                        <IonLabel>Formations</IonLabel>
                    </IonTabButton>

    * */

    const pncBottomToolBar = () => {
        return <IonTabBar slot="bottom">
            <IonTabButton tab="flight" href="/flight">
                <IonIcon aria-hidden="true" icon={airplaneOutline}/>
                <IonLabel>{t('menu.flights')}</IonLabel>
            </IonTabButton>
            <IonTabButton tab="points" href="/points">
                <IonIcon aria-hidden="true" icon={barChartOutline}/>
                <IonLabel>{t('menu.points')}</IonLabel>
            </IonTabButton>
        </IonTabBar>;
    }

    /*

                <IonTabButton tab="trainings" href="/trainings">
                <IonIcon aria-hidden="true" icon={libraryOutline}/>
                <IonLabel>{t('menu.trainings')}</IonLabel>
            </IonTabButton>

    * */

    const ccpBottomToolBar = () => {
        return <IonTabBar slot="bottom">
        </IonTabBar>
    }

    const LoginScreenOrAppContent = () => {
        if (smoothEntry) {
            return <CustomSpinner/>;
        }
        if (auth.shouldAuthenticate) {
            return <IonContent>
                <LoginScreen/>
            </IonContent>;
        }

        if(isCcp(user.details)){
            return <><FlightScreen/></>
        }

        return <IonReactRouter>
            <IonTabs>
                <IonRouterOutlet>
                    <Route exact path="/flight">
                        <FlightScreen/>
                    </Route>
                    <Route exact path="/points">
                        <Points/>
                    </Route>
                    <Route path="/trainings">
                        <Trainings/>
                    </Route>
                    <Route exact path="/">
                        <Redirect to="/flight"/>
                    </Route>
                </IonRouterOutlet>

                {
                    shouldDisplayBottomToolBar(user.details) ? pncBottomToolBar() : ccpBottomToolBar()
                }

            </IonTabs>
        </IonReactRouter>
    }


    return <>
        <LoginScreenOrAppContent/>
    </>
}
