import React, {useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from "../app/hooks";
import {RootState} from "../app/store";
import {refreshToken} from "../helpers/authApiHelper";
import {getAirplaneConfigurations, getCcpFlights, getFlightSurvey} from "../helpers/backendApiHelper";
import {AirplaneConfigurationDto, AuthResponse, FlightInstanceDto, SurveyDto} from "../model";
import {flightActions} from "../features/flight/flightSlice";
import {CustomSpinner} from "../components/CustomSpinner";
import {FlightPostAssignment} from "../components/flights/FlightPostAssignment";
import {sortFlightInstanceByDate} from "../utils/utils";
import {FlightMultiplePncEvaluationScreen} from "../components/flights/FlightMultiplePncEvaluationScreen";
import {IonContent} from "@ionic/react";
import {useTranslation} from "react-i18next";

export const preparingSurveysContent = (t: any) => {
    return <>
        <CustomSpinner>
            {t('fetchingFlightDetails')}
        </CustomSpinner>
    </>;
}

export const CcpFlightScreen: React.FC = () => {
    const dispatch = useAppDispatch();
    const auth = useAppSelector((state: RootState) => state.auth);
    const user = useAppSelector((state: RootState) => state.user);
    const flight = useAppSelector((state: RootState) => state.flight);
    const [isFetchingFlights, setIsFetchingFlights] = useState<boolean>(false);
    const {t} = useTranslation(['pages.flights', 'common']);

    useEffect(() => {

        const fetchCcpFlights = async () => {
            try {
                const authResponse: AuthResponse = await refreshToken(auth.accessToken, auth.refreshToken);
                const incomingFlights: FlightInstanceDto[] = await getCcpFlights({accessToken: authResponse.accessToken});

                setIsFetchingFlights(false);
                if (incomingFlights.length === 0) {
                    return;
                }
                const currentCcpFlight = sortFlightInstanceByDate(incomingFlights, true)[0];
                dispatch(flightActions.onCurrentCcpFlightChange(currentCcpFlight));
                getAirplaneConfigurations({accessToken: authResponse.accessToken}, currentCcpFlight.airplane)
                    .then(configs => {
                        const flightAirplaneConfiguration: AirplaneConfigurationDto | undefined = configs
                            .find(c => c.airplane.registration === currentCcpFlight.airplane && c.flight.reference === currentCcpFlight.flightReference);
                        dispatch(flightActions.onCurrentPlaneConfigurationChange(flightAirplaneConfiguration));
                    });

                getFlightSurvey({accessToken: authResponse.accessToken}, currentCcpFlight.id, () => {
                })
                    .then((survey: SurveyDto | undefined) => {
                        dispatch(flightActions.onCurrentSurveyChange(survey));
                    })

            } catch (reason) {
                console.warn('fail reason: ', reason);
            }

        }

        if (!flight.currentCcpFlight && !flight.hasFetchNextCcpFlights) {
            setIsFetchingFlights(true);
            fetchCcpFlights().then().catch();
        }
    }, [])

    useEffect(() => {
    }, [isFetchingFlights]);

    useEffect(() => {
    }, [user]);

    useEffect(() => {
        if (flight.hasFetchNextCcpFlights) {
            setIsFetchingFlights(false);
        }
    }, [flight]);

    const fetchingFlightsContent = () => {
        return <>
            <CustomSpinner>
                {t('searchingFlight')}
            </CustomSpinner>
        </>;
    }

    const flightDetailsContent = () => {

        if (flight.currentCcpFlight && flight.currentCcpFlight.assignments.length === 0) {
            return <FlightPostAssignment flight={flight.currentCcpFlight}/>
        }

        if (flight.currentCcpFlight && user.details) {
            return <FlightMultiplePncEvaluationScreen
                evaluatorTrigram={user.details.trigram}
                flight={flight.currentCcpFlight}
                airplaneConfiguration={flight.currentPlaneConfiguration}
                survey={flight.currentSurvey}
            />
        }

        return <div/>;
    }

    return <>
        {
            isFetchingFlights ?
                fetchingFlightsContent() :
                flight.currentCcpFlight ?
                    flight.currentSurvey ?
                        flightDetailsContent()
                        :
                        preparingSurveysContent(t)
                    :
                    <IonContent>
                        <div className={'middle-content centered'}>
                            {t('noCcpFlight')}
                        </div>
                    </IonContent>
        }
    </>;
}
