import {AirplaneConfigurationDto, AuthResponse, FlightEvaluation, FlightInstanceDto, SurveyDto} from "../../model";
import {useEffect, useState} from "react";
import {Survey} from "../Survey";
import './FlightMultiplePncEvaluationScreen.css';
import {useAppSelector} from "../../app/hooks";
import {RootState} from "../../app/store";
import {PersonChip} from "../PersonChip";
import {IonCol, IonGrid, IonIcon, IonLabel, IonRow} from "@ionic/react";
import {arrowUpOutline, checkmark, checkmarkDoneOutline} from "ionicons/icons";
import {refreshToken} from "../../helpers/authApiHelper";
import {getFlightsEvaluationsWithFilter} from "../../helpers/backendApiHelper";
import {preparingSurveysContent} from "../../pages/CcpFlightScreen";
import {useTranslation} from "react-i18next";

export interface FlightMultiplePncEvaluationScreenProps {
    evaluatorTrigram: string;
    flight: FlightInstanceDto;
    airplaneConfiguration: AirplaneConfigurationDto | undefined;
    survey: SurveyDto | undefined;
}

export const FlightMultiplePncEvaluationScreen = (props: FlightMultiplePncEvaluationScreenProps) => {

    const [currentPnc, setCurrentPnc] = useState<string>('');
    const [evaluatedPncs, setEvaluatedPncs] = useState<string[]>([]);
    const [hasDoneAllEvaluations, setHasDoneAllEvaluations] = useState<boolean>(false);
    const [lastUpdate, setLastUpdate] = useState<Date>(new Date());
    const user = useAppSelector((state: RootState) => state.user);
    const auth = useAppSelector((state: RootState) => state.auth);
    const [hasFetchCurrentFlightEvaluation, setHasFetchCurrentFlightEvaluation] = useState<boolean>(false);
    const {t} = useTranslation(['pages.flights', 'common']);

    useEffect(() => {
        const fetchFlights = async () => {
            const authResponse: AuthResponse = await refreshToken(auth.accessToken, auth.refreshToken);
            const currentFlightEvaluations: FlightEvaluation[] = await getFlightsEvaluationsWithFilter(
                {accessToken: authResponse.accessToken},
                {flightExternalId: props.flight.id});

            const alreadyEvaluatedPncs: string[] = [];
            currentFlightEvaluations.forEach(fe => {
                if (fe.hasBeenEvaluated) {
                    alreadyEvaluatedPncs.push(fe.assessedTrigram);
                }
            });

            alreadyEvaluatedPncs.forEach(pnc => {
                if (!evaluatedPncs.includes(pnc)) {
                    evaluatedPncs.push(pnc);
                }
            })

            setEvaluatedPncs(evaluatedPncs);
            setLastUpdate(new Date());

        }
        if (!hasFetchCurrentFlightEvaluation) {
            fetchFlights().then(() => {
                setHasFetchCurrentFlightEvaluation(true);
                setLastUpdate(new Date());
            })
                .catch()
        }
    }, []);

    const updateHasDoneAllEvaluations = () => {
        if (props.airplaneConfiguration) {
            const toBeEvaluatedPncs: string[] = [];
            props.flight.pncs.forEach(pnc => {
                if (isPncUnderMe(pnc)) {
                    toBeEvaluatedPncs.push(pnc);
                }
            })

            if (evaluatedPncs.length === toBeEvaluatedPncs.length) {
                setHasDoneAllEvaluations(true);
            }
        }
    }

    useEffect(() => {
        updateHasDoneAllEvaluations();
    }, [evaluatedPncs, currentPnc, lastUpdate])

    const isPncUnderMe = (pnc: string): boolean => {
        //@ts-ignore
        const pncPost: string = props.flight.assignments.find(a => a.pnc === pnc).post;
        //@ts-ignore
        const isPncUnderCc: boolean = props.airplaneConfiguration && props.airplaneConfiguration.posts.find(p => p.name === pncPost).isUnderCc;

        const isEvaluatorCcpForFlight: boolean = props.flight.ccp === props.evaluatorTrigram;

        return !isPncUnderCc && isEvaluatorCcpForFlight;
    }

    const pncList = () => {
        return <div className={'pnc-list centered'}> {
            props.flight.pncs.map((pnc, index) => {

                if (isPncUnderMe(pnc)) {
                    return <div key={index}>
                        <PersonChip
                            trigram={pnc}
                            isSelected={pnc === currentPnc}
                            isDisabled={false}
                            isDone={evaluatedPncs.includes(pnc)}
                            onSelect={() => {
                                setCurrentPnc(pnc)
                            }}
                        />
                    </div>
                }
                return <div key={index}/>
            })
        }
        </div>
    }

    if (!hasFetchCurrentFlightEvaluation) {
        return preparingSurveysContent(t);
    }

    const buildPncEvaluationDoneMessage = (pnc: string): string => {
        return t('evaluation.pncDone1') + ' ' + pnc + ' ' + t('evaluation.pncDone2')
    }

    return <>
        {pncList()}

        <div className={'overflow-zone'}>
            {
                props.survey
                && currentPnc !== ''
                && !evaluatedPncs.find(t => t === currentPnc)
                && user.details
                    ?
                    <Survey
                        items={props.survey.items}
                        assessedTrigram={currentPnc}
                        evaluatorTrigram={user.details.trigram}
                        flightInstanceId={props.flight.id}
                        surveyId={props.survey.id}
                        onSave={() => {
                            evaluatedPncs.push(currentPnc);
                            setEvaluatedPncs(evaluatedPncs);
                            setLastUpdate(new Date());
                        }}
                    />
                    :
                    hasDoneAllEvaluations ?
                        <div className={'centered-message'}>
                            <IonGrid>
                                <IonRow>
                                    <IonCol className={'centered padded'}>
                                        {t('evaluation.allSaved')}
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol className={'centered'}>
                                        <IonIcon icon={checkmarkDoneOutline}/>
                                    </IonCol>
                                </IonRow>
                            </IonGrid>
                        </div>
                        :
                        evaluatedPncs.find(t => t === currentPnc) ?
                            <div className={'centered-message centered'}>
                                <IonLabel>
                                    {buildPncEvaluationDoneMessage(currentPnc)}
                                </IonLabel>
                                <div className={'left-padding-10'}>
                                    <IonIcon icon={checkmark}/>
                                </div>
                            </div>
                            :
                            <div className={'centered-message'}>
                                <IonGrid>
                                    <IonRow>
                                        <IonCol className={'centered'}>
                                            <div className={'up-and-down'}>
                                                <IonIcon icon={arrowUpOutline}/>
                                            </div>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol className={'centered'}>
                                            {t('evaluation.selectPnc')}
                                        </IonCol>
                                    </IonRow>
                                </IonGrid>
                            </div>
            }
        </div>
    </>
}
