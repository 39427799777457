import React, {useEffect, useState} from "react";
import {Survey} from "./Survey";
import {SurveyReport} from "./SurveyReport";
import {useAppSelector} from "../app/hooks";
import {RootState} from "../app/store";
import {hasBeenFullyEvaluated, hasFilledSurvey, isOldFlight} from "../utils/utils";
import {CustomSpinner} from "./CustomSpinner";
import {IonLabel} from "@ionic/react";
import {useTranslation} from "react-i18next";

export const CurrentFlight = () => {
    const flightState = useAppSelector((state: RootState) => state.flight);
    const surveyState = useAppSelector((state: RootState) => state.survey);
    const userState = useAppSelector((state: RootState) => state.user);

    const [isCurrentFlightEvaluated, setIsCurrentFlightEvaluated] = useState(false);
    const [shouldFillSurvey, setShouldFillSurvey] = useState(false);
    const [hasFillSurvey, setHasFillSurvey] = useState(false);
    const {t} = useTranslation(['pages.flights', 'common']);

    const isRecentFlight = (): boolean => {
        if (!flightState.current) {
            return false;
        }
        return !isOldFlight(flightState.current);
    }

    const hasFetchCurrentSurvey = (): boolean => {
        return flightState.currentSurvey !== undefined;
    }

    const isCurrentFlightEvaluationAvailable = (): boolean => {
        if (!flightState.current) {
            return false;
        }
        return hasBeenFullyEvaluated(flightState.current);
    }

    useEffect(() => {
        if (isCurrentFlightEvaluationAvailable()) {
            setIsCurrentFlightEvaluated(true);
        }

        if (isRecentFlight() && hasFetchCurrentSurvey() && !hasFilledSurvey(flightState, surveyState)) {
            setShouldFillSurvey(true);
        } else {
            setShouldFillSurvey(false);
        }

        if (hasFilledSurvey(flightState, surveyState)) {
            setHasFillSurvey(true);
        }
    }, [flightState, userState, surveyState]);

    useEffect(() => {
    }, [hasFillSurvey])

    return <div className={'overflow-zone-single'}>
        {
            isCurrentFlightEvaluated
                ?
                <SurveyReport/>
                :
                hasFillSurvey
                    ?
                    <div className={'centered-message-notification centered'}>
                        <IonLabel>
                            {t('evaluation.pncCompleted')}
                        </IonLabel>
                    </div>
                    :
                    shouldFillSurvey
                        ?
                        flightState.currentSurvey && flightState.current && userState.details ?
                            <Survey items={flightState.currentSurvey.items}
                                    surveyId={flightState.currentSurvey.id}
                                    flightInstanceId={flightState.current.flightInstanceId}
                                    evaluatorTrigram={userState.details.trigram}
                                    assessedTrigram={userState.details.trigram}
                                    onSave={() => {
                                        setHasFillSurvey(true)
                                    }}
                            />
                            :
                            <CustomSpinner/>
                        :
                        <CustomSpinner/>
        }
    </div>
}
