import {useEffect, useState} from "react";

export const WaitingDots = () => {
    const [dotsCount, setDotsCount] = useState(0);

    useEffect(() => {
        setTimeout(() => {
            if (dotsCount > 2) {
                setDotsCount(0);
            } else {
                setDotsCount(dotsCount + 1);
            }
        }, 500)
    }, [dotsCount])

    const printDots = () => {
        let dots = "";

        for (let i = 0; i < dotsCount; i++) {
            dots += ".";
        }

        return dots;
    }

    return <>{printDots()}</>;
}
