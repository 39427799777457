import {configureStore} from "@reduxjs/toolkit";
import authReducer from "../features/authentication/authSlice";
import flightReducer from "../features/flight/flightSlice";
import trainingReducer from "../features/trainings/trainingSlice";
import userReducer from "../features/user/userSlice";
import authMiddleware from "../middleware/authMiddleware";
import flightsMiddleware from "../middleware/flightMiddleware";
import userMiddleware from "../middleware/userMiddleware";
import surveyReducer from "../features/survey/surveySlice";
import surveyMiddleware from "../middleware/surveyMiddleware";


export const store = configureStore({
    reducer: {
        auth: authReducer,
        flight: flightReducer,
        training: trainingReducer,
        user: userReducer,
        survey: surveyReducer
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware()
        .concat(
            authMiddleware,
            flightsMiddleware,
            userMiddleware,
            surveyMiddleware
        )
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
