import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {TrainingDetails} from "../../model";


export interface trainingState {
    current: TrainingDetails | undefined;
}

const initialState: trainingState = {
    current: undefined
}

export const trainingSlice = createSlice({
    name: 'training',
    initialState,
    reducers: {
        onCurrentTrainingChange: (state, action: PayloadAction<TrainingDetails | undefined>) => {
            if (action.payload) {
                state.current = action.payload
            } else {
                state.current = undefined;
            }
        }
    }
})

export const trainingActions = trainingSlice.actions

export default trainingSlice.reducer
