import React from 'react';
import {createRoot} from 'react-dom/client';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import {Provider} from "react-redux";
import {store} from "./app/store";
import {I18nextProvider} from "react-i18next";
import {i18n} from "./i18n/i18n";

const container = document.getElementById('root');
const root = createRoot(container!);

/*
*
*     <Provider store={store}>
        <React.StrictMode>
            <I18nextProvider i18n={i18n}>
                <App/>
            </I18nextProvider>
        </React.StrictMode>
    </Provider>
* */
root.render(
    <Provider store={store}>
        <React.StrictMode>
            <I18nextProvider i18n={i18n}>
                <App/>
            </I18nextProvider>
        </React.StrictMode>
    </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
