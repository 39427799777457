import {
    COMPLIANT,
    Contact,
    TeamMember,
    SurveyAnswer,
    SurveyItem,
    TO_BE_IMPROVED
} from "./model";

export const BRIAN: Contact = {
    id: 'efmakf983',
    email: 'brian.epstein@beatles.com',
    firstName: 'Brian',
    lastName: 'Epstein'
}

export const JOHN: Contact = {
    id: 'aefjn983',
    email: 'john.lennon@beatles.com',
    firstName: 'John',
    lastName: 'Lennon'
}

export const PAUL: Contact = {
    id: 'lfjke872',
    email: 'paul.mccartney@beatles.com',
    firstName: 'Paul',
    lastName: 'McCartney'
}

export const RINGO: Contact = {
    id: 'oefjiz863',
    email: 'ringo.starr@beatles.com',
    firstName: 'Ringo',
    lastName: 'Starr'
}

export const MANAGER: TeamMember = {
    id: "captainId",
    contactDetails: BRIAN,
    trigram: 'BEP'
}

export const MEMBER1: TeamMember = {
    id: "member1Id",
    contactDetails: JOHN,
    trigram: 'JLE'
}

export const MEMBER2: TeamMember = {
    id: "member2Id",
    contactDetails: PAUL,
    trigram: 'PMC'
}

export const MEMBER3: TeamMember = {
    id: "member3Id",
    contactDetails: RINGO,
    trigram: 'RST'
}
/*
export const CURRENT_FLIGHT: Flight = {
    id: '3',
    ref: 'AF-3456',
    date: '2023-03-14',
    manager: MANAGER,
    crew: [MEMBER1, MEMBER2, MEMBER3],
    survey: {
        answers: [],
        managerAnswers: [],
        member: MEMBER1
    }
}
*/
/*
export const SURVEY_ITEMS: SurveyItem[] = [
    {id: "0", category: "Accueil", question: "A été souriant"},
    {id: "1", category: "Accueil", question: "A mis sa cravate"},
    {id: "2", category: "Accueil", question: "A dit bonjour"},
    {id: "3", category: "Service", question: "A proposé une boisson"},
    {id: "4", category: "Service", question: "A vendu des produits détaxés"},
    {id: "5", category: "Sol", question: "Enregistrement bagage"},
    {id: "6", category: "Sol", question: "Vérification passeports"},
]
*/
export const SURVEY_ITEMS: SurveyItem[] = [
    {id: "0", category: "briefing", question: "Ponctualité"},
    {id: "1", category: "briefing", question: "Uniforme hygiène et posture"},
    {id: "2", category: "briefing", question: "Attention et préparation"},
    {id: "3", category: "preparation", question: "Vérification du chargement"},
    {id: "4", category: "preparation", question: "Compte rendu des items manquants"},
    {id: "5", category: "preparation", question: "Vérification du standard de la cabine"},
    {id: "6", category: "boarding", question: "Sourire, posture générale"},
    {id: "7", category: "boarding", question: "Attention relationnelle"},
    {id: "8", category: "inFlight", question: "Service restauration"},
    {id: "9", category: "inFlight", question: "Entraide et anticipation"},
    {id: "10", category: "inFlight", question: "Mise en valeur"},
    {id: "11", category: "inFlight", question: "Gestion des biens"},
    {id: "12", category: "inFlight", question: "Disponibilité et posture snack"},
    {id: "13", category: "inFlight", question: "Maintien propreté cabine"},
    {id: "14", category: "inFlight", question: "Prise de congés et salutations"},
    {id: "15", category: "inFlight", question: "Vérification et rangement des zones"},
    {id: "16", category: "inFlight", question: "Respect de la hiérarchie"},
]
/*
export const PREVIOUS_FLIGHTS_AND_EVALUATIONS: FlightEvaluation[] = [
    {
        flight: {
            id: '1',
            ref: 'AF-2345',
            date: '2023-01-14',
            manager: MANAGER,
            crew: [MEMBER1, MEMBER3]
        },
        evaluation: {
            manager: 100,
            self: 80,
            bonus: 100
        }
    },
    {
        flight: {
            id: '2',
            ref: 'AF-1234',
            date: '2023-01-18',
            manager: MANAGER,
            crew: [MEMBER1, MEMBER2]
        },
        evaluation: {
            manager: 120,
            self: 120,
            bonus: 150
        }
    },
    {
        flight: {
            id: '3',
            ref: 'AF-AA34',
            date: '2023-01-23',
            manager: MANAGER,
            crew: [MEMBER1, MEMBER2]
        },
        evaluation: {
            manager: 140,
            self: 120,
            bonus: 200
        }
    },
    {
        flight: {
            id: '4',
            ref: 'AF-A134',
            date: '2023-02-01',
            manager: MANAGER,
            crew: [MEMBER1, MEMBER2]
        },
        evaluation: {
            manager: 120,
            self: 120,
            bonus: 250
        }
    },
    {
        flight: {
            id: '5',
            ref: 'AF-AV234',
            date: '2023-02-07',
            manager: MANAGER,
            crew: [MEMBER1, MEMBER2]
        },
        evaluation: {
            manager: 120,
            self: 120,
            bonus: 150
        }
    },
    {
        flight: {
            id: '6',
            ref: 'AF-7892',
            date: '2023-02-14',
            manager: MANAGER,
            crew: [MEMBER1, MEMBER2]
        },
        evaluation: {
            manager: 100,
            self: 140,
            bonus: 250
        }
    }
]
*/
export const CURRENT_FLIGHT_MANAGER_ANSWERS: SurveyAnswer[] = [
    {itemId: '0', answer: COMPLIANT},
    {itemId: '1', answer: TO_BE_IMPROVED},
    {itemId: '2', answer: COMPLIANT},
    {itemId: '3', answer: TO_BE_IMPROVED},
    {itemId: '4', answer: COMPLIANT},
    {itemId: '5', answer: COMPLIANT},
    {itemId: '6', answer: COMPLIANT}
]

