import {IonCol, IonGrid, IonIcon, IonItem, IonLabel, IonRow} from "@ionic/react";
import './FlightItem.css';
import {documentTextOutline, eyeOutline} from "ionicons/icons";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {flightActions} from "../../features/flight/flightSlice";
import {hasBeenFullyEvaluated, isOldFlight} from "../../utils/utils";
import {FlightEvaluation, SurveyDto, WeaknessesDto} from "../../model";
import {getFlightSurvey, getFlightWeaknesses} from "../../helpers/backendApiHelper";
import {RootState} from "../../app/store";
import {refreshToken} from "../../helpers/authApiHelper";

export interface FlightItemProps {
    item: FlightEvaluation
}

export const FlightItem = (props: FlightItemProps) => {
    const dispatch = useAppDispatch();
    const auth = useAppSelector((state: RootState) => state.auth);

    const formatDate = (date: string) => {
        return new Date(date).toISOString().slice(0, 10);
    }

    const fetchCurrentSurvey = async (currentFlightId: string) => {
        refreshToken(auth.accessToken, auth.refreshToken)
            .then((authResponse) => {
                getFlightSurvey(authResponse, currentFlightId, () => {
                    console.error('unable to get FlightSurvey')
                })
                    .then((survey: SurveyDto | undefined) => {
                        dispatch(flightActions.onCurrentSurveyChange(survey));
                    })
            })
            .catch(() => {
            });
    }

    const fetchCurrentFlightWeaknesses = async (currentFlightId: string) => {
        refreshToken(auth.accessToken, auth.refreshToken)
            .then((authResponse) => {
                getFlightWeaknesses(authResponse, currentFlightId)
                    .then((weaknessesDto: WeaknessesDto) => {
                        dispatch(flightActions.onCurrentFlightWeaknessChange(weaknessesDto));
                    })
            })
            .catch(() => {
            });
    }

    const shouldEditFlight = (): boolean => {
        //only for PNC case, for CCP other hasBeen... to be checked
        return (!isOldFlight(props.item) && !props.item.hasBeenAutoEvaluated);
    }

    const onSelectFlight = () => {
        dispatch(flightActions.onCurrentFlightChange(props.item));
        fetchCurrentFlightWeaknesses(props.item.flightInstanceId).then().catch();
        if (shouldEditFlight()) {
            fetchCurrentSurvey(props.item.flightInstanceId).then().catch();
        }
    }

    const displayableScore = () => {
        if (hasBeenFullyEvaluated(props.item)) {
            return <>{props.item.totalScore}</>
        }

        return <div/>;
    }

    return <IonItem className={shouldEditFlight() ? '' : 'old-flight'}>
        <IonGrid style={{padding: 0}}>
            <IonRow className={'centered'}>
                <IonCol size={'3'}>
                    <IonLabel>
                        {props.item.flightReference}
                    </IonLabel>
                </IonCol>
                <IonCol size={'4'} style={{textAlign: 'right'}}>
                    <IonLabel>
                        {formatDate(props.item.date.toString())}
                    </IonLabel>
                </IonCol>
                <IonCol size={'2'}>
                    <IonLabel>
                        {
                            hasBeenFullyEvaluated(props.item)
                                ?
                                <div>{props.item.evaluatorTrigram}</div>
                                : <div/>
                        }

                    </IonLabel>
                </IonCol>
                <IonCol size={'1'} className={'centered'}>
                    {
                        shouldEditFlight()
                            ?
                            <div/>
                            :
                            <div>
                                {displayableScore()}
                            </div>
                    }
                </IonCol>
                <IonCol size={'2'} style={{textAlign: 'right'}}>
                    {shouldEditFlight()
                        ?
                        <IonIcon
                            size={'small'}
                            onClick={() => onSelectFlight()}
                            icon={documentTextOutline}/> :
                        <IonIcon
                            style={{opacity: 0.7}}
                            size={'small'}
                            onClick={() => onSelectFlight()}
                            icon={eyeOutline}/>
                    }
                </IonCol>
            </IonRow>
        </IonGrid>
    </IonItem>
}
