import {SurveyAnswer, SurveyAnswerDto, SurveyItem, SurveyItemAnswer} from "../model";
import React, {useEffect, useRef, useState} from "react";
import {IonButton, IonCard, IonCardContent, IonCardHeader, IonCol, IonGrid, IonIcon, IonRow} from "@ionic/react";
import {StandardSurveyItemAnswerInput} from "./StandardSurveyItemAnswerInput";
import './Survey.css';
import {saveOutline} from "ionicons/icons";
import {useAppDispatch, useAppSelector} from "../app/hooks";
import {flightActions} from "../features/flight/flightSlice";
import {postFlightSurvey} from "../helpers/backendApiHelper";
import {RootState} from "../app/store";
import {refreshToken} from "../helpers/authApiHelper";
import {surveyActions} from "../features/survey/surveySlice";
import {useTranslation} from "react-i18next";

export interface SurveyProps {
    items: SurveyItem[],
    evaluatorTrigram: string,
    flightInstanceId: string,
    surveyId: string,
    assessedTrigram?: string,
    onSave?: () => void
}

export const Survey = (props: SurveyProps) => {
    const dispatch = useAppDispatch();
    const auth = useAppSelector((state: RootState) => state.auth);
    const [categories, setCategories] = useState<string[]>([]);
    const [answers, setAnswers] = useState<Map<string, SurveyItemAnswer>>(new Map());
    const [hasStartedSurvey, setHasStartedSurvey] = useState<boolean>(false);
    const [canBeSaved, setCanBeSaved] = useState<boolean>(false);
    const bottomDiv = useRef<HTMLDivElement>(null);
    const {t} = useTranslation(['pages.flights', 'common']);

    useEffect(() => {
        bottomDiv.current && bottomDiv.current.scrollIntoView({behavior: "smooth"});
    }, [canBeSaved]);

    useEffect(() => {
        // @ts-ignore
        setCategories([...new Set(props.items.map(item => item.category))]);
    }, [props.items]);

    const onSelectAnswer = (id: string, answer: SurveyItemAnswer) => {
        setHasStartedSurvey(true);
        answers.set(id, answer);
        setAnswers(answers);

        if (answers.size === props.items.length) {
            setCanBeSaved(true);
        }
    }

    useEffect(() => {
        setCanBeSaved(false);
        setAnswers(new Map());
    }, [props.assessedTrigram]);

    const onSaveSurvey = () => {
        let serializedAnswers: SurveyAnswer[] = [];
        let postAnswers: SurveyAnswerDto[] = [];
        answers.forEach((v, k) => {
            serializedAnswers.push({
                itemId: k,
                answer: v
            })
            postAnswers.push({
                itemId: k,
                answerRef: v.ref
            })
        })
        dispatch(flightActions.onFillSurvey(serializedAnswers));

        refreshToken(auth.accessToken, auth.refreshToken)
            .then((authResponse) => {

                postFlightSurvey(authResponse, {
                    //@ts-ignore
                    surveyId: props.surveyId,
                    //@ts-ignore
                    assessedTrigram: props.assessedTrigram ? props.assessedTrigram : props.evaluatorTrigram,
                    //@ts-ignore
                    evaluatorTrigram: props.evaluatorTrigram,
                    //@ts-ignore
                    flightInstanceId: props.flightInstanceId,
                    answers: postAnswers
                }, () => {
                    console.error('unable to post FlightSurvey')
                }).then(
                    () => {
                        //diff entre auto eval et eval
                        if (props.assessedTrigram && props.assessedTrigram === props.evaluatorTrigram) {
                            //@ts-ignore
                            dispatch(surveyActions.onPncFillSurvey(props.surveyId));
                        }
                        props.onSave && props.onSave();
                    }
                );
            })
            .catch(() => {
            });
        //}

    }

    const computeCallToSurveyBlocClasses = (): string => {
        let classNames: string = 'call-to-survey';
        if (canBeSaved) {
            classNames += ' can-save'
            return classNames;
        }
        classNames += ' initial'
        return classNames;
    }

    const computeCallToSurveySentence = (): string => {
        let sentence: string;
        if (canBeSaved) {
            sentence = t('evaluation.callToValidate')
            return sentence;
        }
        if (hasStartedSurvey) {
            sentence = 'Invitation à tout remplir'
            return sentence;
        }
        sentence = "Phrase d'invitation à remplir le formulaire"
        return sentence;
    }

    const buildCategoriesItems = () => {
        return categories.map((c, i) => {
            return <div key={i} className={'category-block'}>
                <IonCard>
                    <IonCardHeader>
                        {c}
                    </IonCardHeader>
                    <IonCardContent>
                        {props.items.filter(i => i.category === c).map((item, i) => {
                            return <div style={{width: '100%'}} key={i}>
                                <IonGrid>
                                    <IonRow>
                                        <IonCol className={'item-question'}>
                                            {item.question}
                                        </IonCol>
                                    </IonRow>
                                </IonGrid>
                                <StandardSurveyItemAnswerInput
                                    id={props.assessedTrigram ? props.assessedTrigram + item.id : item.id}
                                    surveyItemId={item.id}
                                    onSelectAnswer={onSelectAnswer}
                                />
                            </div>
                        })}
                    </IonCardContent>
                </IonCard>
            </div>
        });
    }

    //const platformOffset = isPlatform('android') ? 160 : 165;
    //const offset = canBeSaved ? 56 : 0;
    //const h = window.innerHeight - (platformOffset + offset);

    return <>
        <div className={canBeSaved ? 'blurred' : ''}>
            {buildCategoriesItems()}
        </div>
        <div className={'empty-space'}/>
        {canBeSaved ?
            <div className={computeCallToSurveyBlocClasses()}
                 onClick={() => {
                     if (canBeSaved) {
                         onSaveSurvey();
                     }
                 }}>

                <div
                    className={'sentence-initial'}>
                    <IonButton expand="block"
                               shape="round"
                               onClick={() => {
                                   if (canBeSaved) {
                                       onSaveSurvey();
                                   }
                               }}
                    >
                        {computeCallToSurveySentence()}

                        <div className={'save-icon'}>
                            <IonIcon style={{width: '23px', height: '23px', opacity: 0.85}} icon={saveOutline}/>
                        </div>
                    </IonButton>

                </div>
            </div>
            : <></>
        }

    </>
}
