import {AuthResponse, FlightInstanceDto, PostAssignation} from "../../model";
import {IonButton, IonCol, IonGrid, IonLabel, IonRow, IonTitle} from "@ionic/react";
import './FlightPostAssignment.css';
import React, {useEffect, useState} from "react";
import {PostAssignment} from "./PostAssignment";
import {postFlightAssignations} from "../../helpers/backendApiHelper";
import {useAppSelector} from "../../app/hooks";
import {RootState} from "../../app/store";
import {refreshToken} from "../../helpers/authApiHelper";
import {useTranslation} from "react-i18next";
import {DraggablePnc} from "./DraggablePnc";

export interface FlightPostAssignmentProps {
    flight: FlightInstanceDto
}

export const FlightPostAssignment = (props: FlightPostAssignmentProps) => {

    const auth = useAppSelector((state: RootState) => state.auth);

    const [selectedPnc, setSelectedPnc] = useState<string>('');
    const [selectedPost, setSelectedPost] = useState<string>('');
    const [availablePncs, setAvailablePncs] = useState<string[]>([]);
    const [availablePosts, setAvailablePosts] = useState<string[]>([]);
    const emptyMap: Map<string, string> = new Map<string, string>();
    const [postsAssignations, setPostsAssignations] = useState<Map<string, string>>(emptyMap);
    const [postsPositions, setPostsPositions] = useState<Map<string, DOMRect>>(new Map<string, DOMRect>());
    const [lastPostAssignationDate, setLastPostAssignationDate] = useState<Date>(new Date());
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [hasBeenSaved, setHasBeenSaved] = useState<boolean>(false);
    const {t} = useTranslation(['pages.flights', 'common']);

    useEffect(() => {
        const initPostsAssignations: Map<string, string> = new Map<string, string>();
        props.flight.posts.forEach(p => initPostsAssignations.set(p, ''));
        setAvailablePncs(props.flight.pncs);
        setAvailablePosts(props.flight.posts);
        setPostsAssignations(initPostsAssignations);
    }, [])

    const removePncFromAvailable = () => {
        const newAvailablePncs: string[] = [];
        availablePncs.forEach(p => {
            if (selectedPnc !== p) {
                newAvailablePncs.push(p)
            }
        });
        setAvailablePncs(newAvailablePncs);
    }

    const addAvailablePnc = (pnc: string) => {
        if (!availablePncs.includes(pnc)) {
            availablePncs.push(pnc);
            setAvailablePncs(availablePncs)
        }
    }

    const addAvailablePost = (post: string) => {
        if (!availablePosts.includes(post)) {
            availablePosts.push(post);
            setAvailablePosts(availablePosts)
        }
    }

    const removePostFromAvailable = () => {
        const newAvailablePosts: string[] = [];
        availablePosts.forEach(p => {
            if (selectedPost !== p) {
                newAvailablePosts.push(p)
            }
        });
        setAvailablePosts(newAvailablePosts);
    }

    useEffect(() => {
        if ('' !== selectedPnc && '' !== selectedPost) {
            setSelectedPnc('');
            setSelectedPost('');
            postsAssignations.set(selectedPost, selectedPnc);
            setPostsAssignations(postsAssignations);
            setLastPostAssignationDate(new Date());
            removePncFromAvailable();
            removePostFromAvailable();
        }
    }, [selectedPnc, selectedPost, availablePncs, availablePosts])

    useEffect(() => {
    }, [lastPostAssignationDate])

    useEffect(() => {
        const performSave = async () => {
            const postAssigns: PostAssignation[] = [];
            postsAssignations.forEach((pnc, post) => {
                postAssigns.push({
                    post: post,
                    pnc: pnc
                })
            });

            try {
                const authResponse: AuthResponse = await refreshToken(auth.accessToken, auth.refreshToken);
                return await postFlightAssignations({accessToken: authResponse.accessToken}, {
                    flightId: props.flight.id,
                    postAssignations: postAssigns
                });
            } catch (e) {
                console.warn('Unable to save assignations: ', e);
            }
        }

        if (isSaving) {
            performSave()
                .then(() => {
                    setIsSaving(false);
                    setHasBeenSaved(true);
                })
                .catch(reason => {
                    setIsSaving(false);
                    setHasBeenSaved(false);
                    console.warn('Unable to save assignations: ', reason);
                })
        }

    }, [isSaving])

    const submitPostAssignations = () => {
        setIsSaving(true);
    }

    const atLeastOnePostIsAssigned = (): boolean => {
        let result: boolean = false;
        postsAssignations.forEach((pnc) => {
            if ('' !== pnc) {
                result = true;
            }
        })

        return result;
    }

    const attributionHintContent = () => {
        if (atLeastOnePostIsAssigned()) {
            return <></>
        }

        return <>
            <div className={"desc-label"}>
                <IonLabel>
                    {t('postsAssignment.description')}
                </IonLabel>
            </div>
        </>;
    }

    const savedConfirmationMessageContent = () => {
        return <IonLabel>
            {t('postsAssignment.positionsSaved')}
        </IonLabel>;
    }

    const attributionDoneSaveInvitationContent = () => {
        return <div className={"desc-label"}>
            <IonLabel>
                {t('postsAssignment.positionsProvided')}
            </IonLabel>
        </div>;
    }


    const isCollision = (rect1: DOMRect, rect2: DOMRect): boolean => {
        return rect1.x < rect2.x + rect2.width &&
            rect1.x + rect1.width > rect2.x &&
            rect1.y < rect2.y + rect2.height &&
            rect1.y + rect1.height > rect2.y;
    }

    const resolvePosition = (pncTrigram: string, pncRect: DOMRect) => {
        let postRec: DOMRect;
        for (let availablePost of availablePosts) {
            //@ts-ignore
            postRec = postsPositions.get(availablePost);
            if (isCollision(postRec, pncRect)) {
                setSelectedPost(availablePost)
                break;

            }
        }
    }

    const rememberPosition = (post: string, position: DOMRect): void => {
        postsPositions.set(post, position);
        setPostsPositions(postsPositions);
    }

    const availablePncsContent = () => {
        if (hasBeenSaved) {
            return savedConfirmationMessageContent();
        }

        if (availablePncs.length === 0) {
            return attributionDoneSaveInvitationContent();
        }

        return <>
            {
                availablePncs.map((pnc, index) => (
                    <DraggablePnc key={index} pnc={pnc} isSelected={selectedPnc === pnc} onSelect={setSelectedPnc}
                                  onDragStop={resolvePosition}/>
                ))
            }
        </>
    }

    const cancelPncSelection = (pnc: string, post: string) => {
        addAvailablePnc(pnc);
        addAvailablePost(post);
        setSelectedPost('');
        setSelectedPnc('');
        postsAssignations.set(post, '');
        setPostsAssignations(postsAssignations);
        setLastPostAssignationDate(new Date());
    }

    return <>
        <div className={"desc-title"}>
            <IonGrid>
                <IonRow>
                    <IonCol>
                        <IonTitle>
                            {t('postsAssignment.title')}
                        </IonTitle>
                    </IonCol>
                </IonRow>
            </IonGrid>
        </div>

        <div className={'pnc-list centered tier'}>
            {
                availablePncsContent()
            }

        </div>
        <div className={'post-box'}>
            {
                props.flight.posts.map(post => (
                    <div key={post} className={'drop-box'}>
                        <PostAssignment post={post}
                                        pnc={postsAssignations.get(post)}
                                        readonly={hasBeenSaved || isSaving}
                                        onAssignPost={() => {
                                            if (availablePosts.includes(post) && selectedPnc !== '') {
                                                setSelectedPost(post);
                                            }
                                        }}
                                        onCancel={(pnc: string | undefined, post: string) => {
                                            if (pnc) {
                                                cancelPncSelection(pnc, post);
                                            }
                                        }}
                                        onDraw={rememberPosition}
                        />
                    </div>
                ))
            }
        </div>
        <div className={"hint"}>
                {attributionHintContent()}
        </div>
        <div className={'bottom-button'}>
            {availablePncs.length === 0 && !hasBeenSaved && !isSaving ?
                <IonButton expand="block"
                           shape="round"
                           onClick={submitPostAssignations}
                >
                    {t('common:buttons.save')}
                </IonButton>
                :
                <div/>
            }
        </div>
    </>;
}
