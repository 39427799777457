import {CONFIGURATION} from "../configuration";
import {AuthDetails, buildHeaders, get, post} from "./apiHelper";
import {
    AirplaneConfigurationDto,
    FlightEvaluation,
    FlightInstanceAssignationsRequest,
    FlightInstanceDto,
    FlightInstanceSurveyRequestDto,
    PersonDto,
    SurveyDto, WeaknessesDto
} from "../model";

const API_ROOT_URL: string = "/app/api";

export interface EvaluationFilter {
    flightExternalId?: string;
    assessedTrigram?: string;
    assessedEmail?: string;
    evaluatorTrigram?: string;
    evaluatorEmail?: string;
    minDate?: Date;
    maxDate?: Date;
}

export const getFlightsEvaluations = async (authDetails: AuthDetails, userDetails: PersonDto, onError: () => void): Promise<FlightEvaluation[]> => {
    const path: string = API_ROOT_URL + '/me/evaluations';
    const httpResponse = await get(CONFIGURATION.backendUrl, path, buildHeaders(authDetails));

    if (httpResponse.statusCode && httpResponse.statusCode >= 400) {
        onError();
        return [];
    }

    return httpResponse.body;
}

export const getFlightsEvaluationsWithFilter = async (authDetails: AuthDetails, filter: EvaluationFilter): Promise<FlightEvaluation[]> => {
    let path: string = API_ROOT_URL + '/evaluations';

    if (filter.flightExternalId) {
        path += '?flight_id=' + filter.flightExternalId;
    }

    const httpResponse = await get(CONFIGURATION.backendUrl, path, buildHeaders(authDetails));

    if (httpResponse.statusCode && httpResponse.statusCode >= 400) {
        throw new Error("Unable to retrieve flight evaluations");
    }

    return httpResponse.body;
}

export const getCcpFlights = async (authDetails: AuthDetails): Promise<FlightInstanceDto[]> => {
    const path: string = API_ROOT_URL + '/me/flights';
    const httpResponse = await get(CONFIGURATION.backendUrl, path, buildHeaders(authDetails));

    if (httpResponse.statusCode && httpResponse.statusCode >= 400) {
        throw new Error("Unable to retrieve incoming flights");
    }

    return httpResponse.body;
}

export const postFlightAssignations = async (authDetails: AuthDetails, request: FlightInstanceAssignationsRequest): Promise<void> => {
    const path: string = API_ROOT_URL + '/me/flights/' + request.flightId + '/assignations';
    console.debug('POST on path: ', path);
    const httpResponse = await post(CONFIGURATION.backendUrl, path, buildHeaders(authDetails), JSON.stringify(request));

    if (httpResponse.statusCode && httpResponse.statusCode >= 400) {
        throw new Error("Unable to post flight assignations")
    }
}

export const getAirplaneConfigurations = async (authDetails: AuthDetails, airplaneReference: string): Promise<AirplaneConfigurationDto[]> => {
    const path: string = API_ROOT_URL + '/me/airplanes/' + airplaneReference + '/configuration';
    const httpResponse = await get(CONFIGURATION.backendUrl, path, buildHeaders(authDetails));

    if (httpResponse.statusCode && httpResponse.statusCode >= 400) {
        throw new Error("Unable to retrieve airplane configurations for given plane");
    }

    return httpResponse.body;
}

export const getFlightSurvey = async (authDetails: AuthDetails, flightId: string, onError: () => void): Promise<SurveyDto | undefined> => {
    const path: string = API_ROOT_URL + '/me/flights/' + flightId + '/survey';
    const httpResponse = await get(CONFIGURATION.backendUrl, path, buildHeaders(authDetails));

    if (httpResponse.statusCode && httpResponse.statusCode >= 400) {
        onError();
        return undefined;
    }

    return httpResponse.body;
}

export const getFlightWeaknesses = async (authDetails: AuthDetails, flightId: string): Promise<WeaknessesDto> => {
    const path: string = API_ROOT_URL +  '/me/flights/' + flightId + '/weaknesses';
    const httpResponse = await get(CONFIGURATION.backendUrl, path, buildHeaders(authDetails));

    if (httpResponse.statusCode && httpResponse.statusCode >= 400) {
        throw new Error("Unable to retrieve current flight weaknesses");
    }

    return httpResponse.body;
}

export const postFlightSurvey = async (authDetails: AuthDetails, request: FlightInstanceSurveyRequestDto, onError: () => void): Promise<void> => {
    const path: string = API_ROOT_URL + '/me/surveys';
    const httpResponse = await post(CONFIGURATION.backendUrl, path, buildHeaders(authDetails), JSON.stringify(request));

    if (httpResponse.statusCode && httpResponse.statusCode >= 400) {
        onError();
    }
}

export const getMe = async (authDetails: AuthDetails, onError: () => void): Promise<PersonDto | undefined> => {
    const path: string = API_ROOT_URL + '/me';
    const httpResponse = await get(CONFIGURATION.backendUrl, path, buildHeaders(authDetails));

    if (httpResponse.statusCode && httpResponse.statusCode >= 400) {
        onError();
        return undefined;
    }

    return httpResponse.body;
}
