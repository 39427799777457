export const namespaces = {
    pages: {
        login: "pages.login",
        flights: "pages.flights",
    },
    common: "common",
};

export const languages = {
    fr: "fr",
    en: "en",
};

export const availableLanguages: string[] = [
    languages.en,
    languages.fr
];
