import {CartesianGrid, Line, LineChart, Tooltip, XAxis, YAxis} from "recharts";
import {useEffect, useState} from "react";
import {useAppSelector} from "../app/hooks";
import {RootState} from "../app/store";
import {
    computeTotal,
    formatDateWithSlashes,
    hasBeenFullyEvaluated,
    shouldDisplayFlight,
    sortByDate
} from "../utils/utils";
import './PointsChart.css';

interface ChartDataItem {
    name: string;
    points: number;
}

export const PointsChart = () => {
    const flight = useAppSelector((state: RootState) => state.flight);

    const chartW: number = window.innerWidth > 600 ? window.innerWidth * 0.57 : window.innerWidth * 0.93
    const chartH: number = window.innerHeight > 800 ? window.innerHeight * 0.5 : window.innerHeight * 0.3

    const [chartData, setChartData] = useState<ChartDataItem[]>([]);
    const [chartKey, setChartKey] = useState<string>('');

    useEffect(() => {
        const data: ChartDataItem[] = [];

        sortByDate(flight.flights, true).forEach(pe => {
            if (hasBeenFullyEvaluated(pe)) {
                data.push({
                    name: formatDateWithSlashes(pe.date.toString()),
                    points: pe.totalScore
                })
            } else {
                console.debug('not double evaluated, won t appear for now on graph')
            }
        })
        setChartData(data);
        setChartKey(btoa(chartData.length.toString()))
    }, []);

    /**
     *
     *
     <Legend />
     <Line type="monotone" dataKey="self" stroke="#8884d8"/>
     <Line type="monotone" dataKey="manager" stroke="#82ca9d"/>

     */

    return <div>
        <div className={'empty-block'}/>
        <div className={'screen-title'}>{computeTotal(flight.flights.filter(f => shouldDisplayFlight(f)))} points</div>
        <div className={'graph'}>
            {chartData.length > 0 ?
                <LineChart
                    key={chartKey}
                    width={chartW} height={chartH}
                    margin={{
                        top: 5,
                        right: 40,
                        bottom: 5,
                    }}
                    data={chartData}>
                    <CartesianGrid strokeDasharray="1 11"/>
                    <XAxis dataKey="name" textAnchor="middle"/>
                    <YAxis/>
                    <Tooltip/>
                    <Line type="monotone" dataKey="points" stroke="#196F3D"/>
                </LineChart>
                : <div/>}
        </div>
    </div>
}
