import {IonApp, setupIonicReact} from '@ionic/react';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/app.css';
import './theme/variables.css';
import React from "react";
import {AppContent} from './components/AppContent';
import {useAppDispatch} from "./app/hooks";
import {loadStoredPreferences as loadAuthPreferences} from "./features/authentication/authSlice";
import {loadStoredPreferences as loadUserPreferences} from "./features/user/userSlice";
import {loadStoredPreferences as loadSurveyPreferences} from "./features/survey/surveySlice";

setupIonicReact();


const App: React.FC = () => {
    const dispatch = useAppDispatch();
    dispatch(loadAuthPreferences());
    dispatch(loadUserPreferences());
    dispatch(loadSurveyPreferences());

    return <IonApp>
        <AppContent/>
    </IonApp>
};

export default App;
