import {COMPLIANT, SurveyItemAnswer, TO_BE_IMPROVED, TO_LEARN} from "../model";
import './StandardSurveyItemAnswerInput.css';
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

export interface StandardSurveyItemAnswerInputProps {
    surveyItemId: string;
    onSelectAnswer: (surveyItemId: string, answer: SurveyItemAnswer) => void
    id?: string
}

export const StandardSurveyItemAnswerInput = ({
                                                  id,
                                                  surveyItemId,
                                                  onSelectAnswer
                                              }: StandardSurveyItemAnswerInputProps) => {
    const [selection, setSelection] = useState('');
    const {t} = useTranslation(['pages.flights']);

    useEffect(() => {
        setSelection('');
    }, [id])

    useEffect(() => {
    }, [selection]);

    const select = (answer: SurveyItemAnswer) => {
        setSelection(answer.ref)
        onSelectAnswer(surveyItemId, answer);
    }

    const computeSelectionClasses = (answer: SurveyItemAnswer): string => {
        let basicClass = 'survey-answer';

        if (selection === answer.ref) {
            basicClass += ' selected';
        }
        return basicClass
    }

    const answerButton = (answer: SurveyItemAnswer): JSX.Element => {
        return <div className={computeSelectionClasses(answer)}
                    onClick={() => select(answer)}>
            {t('evaluation.answers.' + answer.ref)}
        </div>;
    }

    return <div id={id ? id : ''}>
        <div className={'inputs'}>
            <div className={'input centered'}>
                {answerButton(TO_LEARN)}
            </div>
            <div className={'input centered'}>
                {answerButton(TO_BE_IMPROVED)}
            </div>
            <div className={'input centered'}>
                {answerButton(COMPLIANT)}
            </div>
        </div>
    </div>

}
