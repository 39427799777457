export interface HttpCustomResponse {
    statusCode: number;
    body: any;
}

export interface HttpCustomError {
    statusCode: number;
    message: string;
}

export interface Contact {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
}

export interface TeamMember {
    id: string;
    trigram: string;
    contactDetails: Contact
}
/*
export interface Survey {
    member: TeamMember,
    answers: SurveyAnswer[],
    managerAnswers: SurveyAnswer[],
}
*/
/*
export interface Flight {
    id: string;
    ref: string;
    date: string;
    manager: TeamMember;
    crew: TeamMember[];
    survey: Survey;
}
*/
export interface SurveyItemAnswer {
    ref: string;
    //label: string;
    points: number;
}

export const TO_LEARN: SurveyItemAnswer = {
    ref: 'std_to_learn',
//    label: 'A acquérir',
    points: 0
}

export const TO_BE_IMPROVED: SurveyItemAnswer = {
    ref: 'std_to_be_improved',
    //label: 'A améliorer',
    points: 0
}

export const COMPLIANT: SurveyItemAnswer = {
    ref: 'std_compliant',
    //label: 'Conforme',
    points: 20
}

export interface SurveyItem {
    id: string;
    category: string;
    question: string;
}

export interface SurveyAnswer {
    itemId: string;
    answer: SurveyItemAnswer;
}

/*
export interface ItemEvaluation {
    itemId: string,
    self: number;
    manager: number;
}

export interface Evaluation {
    itemEvaluations: ItemEvaluation[],
    self: number;
    manager: number;
    bonus: number;
}

export interface FlightEvaluation {
    flight: Flight,
    evaluation: Evaluation
}
*/
export interface AuthResponse {
    accessToken: string;
    refreshToken: string;
}

export interface TrainingDate {
    startDate: string,
    duration: number
}

export interface TrainingDetails {
    category: string;
    title: string;
    description: string;
    trainingDates: TrainingDate[];
}

export interface PersonDto {
    id: string;
    trigram: string;
    firstName: string;
    latName: string;
    email: string;
    roles: string[];
    referent: string | undefined;
}

export interface CategoryEvaluation {
    category: string;
    score: number;
}

export interface FlightEvaluation {
    flightInstanceId: string;
    date: Date;
    flightReference: string;
    airplane: string;
    evaluatorTrigram: string;
    assessedTrigram: string;
    totalScore: number;
    categoryEvaluations: CategoryEvaluation[];
    hasBeenAutoEvaluated: boolean;
    hasBeenEvaluated: boolean;
}

export interface SurveyItemDto {
    id: string;
    category: string;
    question: string;
    answerType: string;
}

export interface SurveyDto {
    id: string;
    name: string;
    items: SurveyItemDto[];
}


export interface SurveyAnswerDto {
    itemId: string;
    answerRef: string;
}

export interface FlightInstanceSurveyRequestDto {
    flightInstanceId: string; //extId
    evaluatorTrigram: string;
    assessedTrigram: string;
    surveyId: string;
    answers: SurveyAnswerDto[];
}

export interface AssignmentDto {
    pnc: string;
    post: string;
}

export interface FlightInstanceDto {
    id: string;
    airplane: string;
    flightReference: string;
    date: Date;
    origin: string;
    destination: string;
    duration: number;
    ccp: string;
    cc: string;
    pncs: string[];
    posts: string[];
    assignments: AssignmentDto[];
}

export interface PostAssignation {
    post: string;
    pnc: string;
}

export interface FlightInstanceAssignationsRequest {
    postAssignations: PostAssignation[];
    flightId: string;
}

export interface AirportDto {
    city: string;
    iataCode: string;
}

export interface AirplaneDto {
    manufacturer: string;
    type: string;
    registration: string;
}

export interface PostDto {
    name: string;
    isUnderCc: boolean;
}

export interface FlightDetails {
    reference: string;
    origin: AirportDto;
    destination: AirportDto;
}

export interface AirplaneConfigurationDto {
    airplane: AirplaneDto;
    flight: FlightDetails;
    posts: PostDto[]
}

export interface WeaknessesDto {
    questions: string[];
}
