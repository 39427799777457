import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {PersonDto} from "../../model";
import {getPreferences, setPreferences} from "../../services/storage.service";

const KEY: string = 'user';

export interface userState {
    details: PersonDto | undefined
}

const initialState: userState = {
    details: undefined
}

export const loadStoredPreferences = createAsyncThunk(
    'user/loadStoredPreferences',
    async () => {
        return await getPreferences<userState>(KEY, initialState)
            .then((result) => {
                return result;
            });
    }
)
export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        onUserDetailsChange: (state, action: PayloadAction<PersonDto>) => {
            console.debug('me: ', action.payload);
            state.details = action.payload;
            setPreferences(KEY, state).then();
        },
        onLoadPreferences: (state, action: PayloadAction<userState>) => {
            const storedState: userState = action.payload;
            state.details = storedState.details;
        },
    },
    extraReducers: (builder) => {
        // Add reducers for additional action types here, and handle loading state as needed
        builder
            .addCase(loadStoredPreferences.fulfilled, () => {
            })
    }
})

export const userActions = userSlice.actions

export default userSlice.reducer
