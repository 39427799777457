import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {
    AirplaneConfigurationDto,
    FlightEvaluation,
    FlightInstanceDto,
    SurveyAnswer,
    SurveyDto,
    WeaknessesDto
} from "../../model";
import {filterOldFlights} from "../../utils/utils";

export interface flightState {
    flights: FlightEvaluation[];
    current: FlightEvaluation | undefined;
    currentFlightWeakness: WeaknessesDto | undefined;
    currentPlaneConfiguration: AirplaneConfigurationDto | undefined;
    currentSurvey: SurveyDto | undefined;
    answers: SurveyAnswer[];
    hasFilledSurvey: boolean;
    previous: FlightEvaluation[];
    currentCcpFlight: FlightInstanceDto | undefined;
    hasFetchNextCcpFlights: boolean;
}

const initialState: flightState = {
    flights: [],
    current: undefined,
    currentFlightWeakness: undefined,
    currentPlaneConfiguration: undefined,
    currentSurvey: undefined,
    answers: [],
    hasFilledSurvey: false,
    previous: [],
    currentCcpFlight: undefined,
    hasFetchNextCcpFlights: false
}

export const flightSlice = createSlice({
    name: 'flight',
    initialState,
    reducers: {
        onFlightsChange: (state, action: PayloadAction<FlightEvaluation[]>) => {
            const oldFlights: FlightEvaluation[] = filterOldFlights(action.payload);
            state.flights = action.payload;
            state.previous = oldFlights;
        },
        onCurrentFlightChange: (state, action: PayloadAction<FlightEvaluation | undefined>) => {
            state.current = action.payload;
        },
        onCurrentSurveyChange: (state, action: PayloadAction<SurveyDto | undefined>) => {
            state.currentSurvey = action.payload;
        },
        onFillSurvey: (state, action: PayloadAction<SurveyAnswer[]>) => {
            state.hasFilledSurvey = action.payload.length > 0;
            state.answers = action.payload;
        },
        onCurrentCcpFlightChange: (state, action: PayloadAction<FlightInstanceDto | undefined>) => {
            state.currentCcpFlight = action.payload
        },
        onHasFetchCcpFlights: (state, action: PayloadAction<boolean>) => {
            state.hasFetchNextCcpFlights = action.payload;
        },
        onCurrentPlaneConfigurationChange: (state, action: PayloadAction<AirplaneConfigurationDto | undefined>) => {
            state.currentPlaneConfiguration = action.payload;
        },
        onCurrentFlightWeaknessChange: (state, action: PayloadAction<WeaknessesDto | undefined>) => {
            state.currentFlightWeakness = action.payload;
        },

    }
})

export const flightActions = flightSlice.actions

export default flightSlice.reducer
