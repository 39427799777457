import {Middleware} from "@reduxjs/toolkit";
import {surveyActions} from "../features/survey/surveySlice";

const surveyMiddleware: Middleware = store => next => action => {
    if (action.type === 'survey/loadStoredPreferences/fulfilled') {
        store.dispatch(surveyActions.onLoadPreferences(action.payload));
    }

    return next(action);
}
export default surveyMiddleware;
