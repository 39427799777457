import {useAppSelector} from "../../app/hooks";
import {RootState} from "../../app/store";
import {useEffect, useState} from "react";
import {PolarAngleAxis, PolarGrid, PolarRadiusAxis, Radar, RadarChart} from "recharts";
import {SURVEY_ITEMS} from "../../dummy";
import {CategoryEvaluation, COMPLIANT} from "../../model";
import './SurveyCharts.css';
import {useTranslation} from "react-i18next";

interface RadarChartDataItem {
    category: string;
    score: number;
    //self: number;
    //manager: number;
}

const normalizeScore = (categoryScore: number, categoryItemCounts: number, maxCategoryItemCounts: number) => {
    const dimensionScore: number = categoryScore;
    const dimensionMaxScore: number = categoryItemCounts * COMPLIANT.points;
    const allDimensionsMaxScore: number = maxCategoryItemCounts * COMPLIANT.points;
    return normalizeDimensionScore(dimensionScore, dimensionMaxScore, allDimensionsMaxScore)
}

const normalizeDimensionScore = (dimensionScore: number, dimensionMaxScore: number, allDimensionsMaxScore: number) => {
    return (dimensionScore / dimensionMaxScore) / allDimensionsMaxScore;
}


export const SurveyChart = () => {
    const chartW: number = window.innerWidth > 600 ? window.innerWidth * 0.57 : window.innerWidth * 0.6
    const chartH: number = window.innerHeight > 800 ? window.innerHeight * 0.5 : window.innerHeight * 0.2

    const flightState = useAppSelector((state: RootState) => state.flight);

    const [chartData, setChartData] = useState([])// useState<RadarChartDataItem[]>([]);
    const [chartKey, setChartKey] = useState<string>('');
    const {t} = useTranslation(['common']);

    const translatedCategory = (category: string): string => {
        return t('survey.categories.' + category);
    }

    useEffect(() => {
        if (flightState.current) {
            const data: RadarChartDataItem[] = [];

            //console.debug('flightState.current: ', flightState.current)
            const categories: string[] = [...new Set(SURVEY_ITEMS.map(si => translatedCategory(si.category)))].reverse();
            //console.debug('categories: ', categories);
            let categoryItemIds: string[];
            let category: string = '';
            const categoryItemCount: Map<string, number> = new Map<string, number>();

            for (let c in categories) {
                category = categories[c];
                //console.debug('-- category ' + category + ' -- categoryItemIds: ', SURVEY_ITEMS.filter(si => si.category === category))
                categoryItemIds = SURVEY_ITEMS.filter(si => translatedCategory(si.category) === category).map(si => si.id);
                categoryItemCount.set(category, categoryItemIds.length);
            }

            const maxCategoryItemCounts = Math.max(...categoryItemCount.values());

            for (let c in categories) {
                category = categories[c];
                categoryItemIds = SURVEY_ITEMS.filter(si => translatedCategory(si.category) === category).map(si => si.id);

                //              const currentCategoryEvaluations: ItemEvaluation[] =
//                    flightState.current.evaluation.itemEvaluations.filter(ie => categoryItemIds.includes(ie.itemId));

                const categoryEvaluation: CategoryEvaluation | undefined = flightState.current.categoryEvaluations.find(ce => ce.category === category);


                data.push({
                    category: translatedCategory(category).split(' - ')[1],
                    score: normalizeScore(categoryEvaluation ? categoryEvaluation.score : 0, categoryItemIds.length, maxCategoryItemCounts)
//                    self: normalizeScore(sum(currentCategoryEvaluations.map(e => e.self)), categoryItemIds.length, maxCategoryItemCounts),
//                    manager: normalizeScore(sum(currentCategoryEvaluations.map(e => e.manager)), categoryItemIds.length, maxCategoryItemCounts),
                })
            }

            setChartKey(Math.random().toString())
            // @ts-ignore
            setChartData(data);
        }
    }, [flightState]);

    return <div className={'graph'}>
        {chartData.length > 0 ?
            <RadarChart key={chartKey}
                        style={{marginLeft: 15, width: '80%', fontSize: 'xx-small'}}
                        height={chartH} width={chartW}
                        outerRadius="80%" data={chartData}>
                <PolarGrid gridType={'circle'}/>
                <PolarAngleAxis dataKey="category"/>
                <PolarRadiusAxis tick={false}/>
                <Radar dataKey="score" stroke="#072f60"
                       fill="green" fillOpacity={0.5}/>
            </RadarChart>
            : <div/>}
    </div>
}
