import {Preferences} from "@capacitor/preferences";

const APP_KEY: string = 'SmartEval';

const computeAppKey = (key: string): string => {
    return APP_KEY + '.' + key;
}

export async function getPreferences<T>(key: string, defaultValue: T): Promise<T> {
    let preferences: T = defaultValue;
    const storedPreferences = await Preferences.get({key: computeAppKey(key)});
    const preferencesAsStrJson: string | null = storedPreferences.value;
    if (preferencesAsStrJson) {
        preferences = JSON.parse(preferencesAsStrJson);

    }
    return preferences;
}

export async function setPreferences<T>(key: string, value: T) {
    await Preferences.set({
        key: computeAppKey(key),
        value: JSON.stringify(value),
    });
}

export const removePreferences = async (key: string) => {
    await Preferences.remove({key: computeAppKey(key)});
};
