import {Middleware} from "@reduxjs/toolkit";
import {userActions} from "../features/user/userSlice";

const userMiddleware: Middleware = store => next => action => {
    if (action.type === 'user/loadStoredPreferences/fulfilled') {
        store.dispatch(userActions.onLoadPreferences(action.payload));
    }

    return next(action);
}
export default userMiddleware;
