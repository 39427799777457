import {
    IonButton,
    IonCol,
    IonContent,
    IonDatetime,
    IonGrid,
    IonHeader,
    IonIcon,
    IonImg,
    IonLabel,
    IonPage,
    IonRow,
    IonToolbar
} from '@ionic/react';
import './Trainings.css';
//import 'react-calendar/dist/Calendar.css';
import React, {useEffect, useRef, useState} from "react";
import {TrainingDetails} from "../model";
import {arrowBackOutline, calendarOutline} from "ionicons/icons";
import {useAppDispatch, useAppSelector} from "../app/hooks";
import {trainingActions} from "../features/trainings/trainingSlice";
import {RootState} from "../app/store";

const TRAININGS: TrainingDetails[] = [
    {
        category: 'Management Opérationnel',
        title: 'Développer son charisme',
        trainingDates: [
            {
                startDate: '2023-04-12',
                duration: 3
            },
            {
                startDate: '2023-04-26',
                duration: 3
            },
            {
                startDate: '2023-05-03',
                duration: 2
            }
        ],
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
    },
    {
        category: 'Management Opérationnel',
        title: 'Manager les X Y et Z',
        trainingDates: [
            {
                startDate: '2023-04-12',
                duration: 2
            },
            {
                startDate: '2023-04-26',
                duration: 2
            },
            {
                startDate: '2023-05-03',
                duration: 3
            }
        ],
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
    },
    {
        category: 'Management Opérationnel',
        title: 'Développer le cycle d’autonomie',
        trainingDates: [
            {
                startDate: '2023-04-12',
                duration: 1
            },
            {
                startDate: '2023-04-26',
                duration: 1
            },
            {
                startDate: '2023-05-03',
                duration: 1
            }
        ],
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
    },
    {
        category: 'Management Opérationnel',
        title: 'Annoncer les Objectifs SMART',
        trainingDates: [
            {
                startDate: '2023-04-12',
                duration: 3
            },
            {
                startDate: '2023-04-26',
                duration: 3
            },
            {
                startDate: '2023-05-03',
                duration: 3
            }
        ],
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
    },

    {
        category: 'Animation d’équipe',
        title: 'Optimiser l’animation du briefing',
        trainingDates: [
            {
                startDate: '2023-04-10',
                duration: 2
            },
            {
                startDate: '2023-04-24',
                duration: 2
            },
            {
                startDate: '2023-05-01',
                duration: 3
            }
        ],
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
    },
    {
        category: 'Animation d’équipe',
        title: 'Donner des consignes engageantes',
        trainingDates: [
            {
                startDate: '2023-04-10',
                duration: 1
            },
            {
                startDate: '2023-04-24',
                duration: 1
            },
            {
                startDate: '2023-05-01',
                duration: 1
            }
        ],
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
    },
    {
        category: 'Animation d’équipe',
        title: 'Comment accompagner et encourager',
        trainingDates: [
            {
                startDate: '2023-04-10',
                duration: 1
            },
            {
                startDate: '2023-04-24',
                duration: 1
            },
            {
                startDate: '2023-05-01',
                duration: 1
            }
        ],
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
    },

    {
        category: 'Codes de la restauration',
        title: 'Evolution du service à bord',
        trainingDates: [
            {
                startDate: '2023-04-11',
                duration: 4
            },
            {
                startDate: '2023-04-25',
                duration: 4
            },
            {
                startDate: '2023-05-02',
                duration: 4
            }
        ],
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
    },
    {
        category: 'Codes de la restauration',
        title: 'Connaissance des vins et alliance mets et vins',
        trainingDates: [
            {
                startDate: '2023-04-11',
                duration: 2
            },
            {
                startDate: '2023-04-25',
                duration: 2
            },
            {
                startDate: '2023-05-02',
                duration: 2
            }
        ],
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
    },
    {
        category: 'Codes de la restauration',
        title: "Maîtriser le contrôle et l'accompagnement",
        trainingDates: [
            {
                startDate: '2023-04-11',
                duration: 1
            },
            {
                startDate: '2023-04-25',
                duration: 1
            },
            {
                startDate: '2023-05-02',
                duration: 1
            }
        ],
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
    },

    {
        category: 'Sécurité',
        title: "Préparer son RTC",
        trainingDates: [
            {
                startDate: '2023-04-12',
                duration: 1
            },
            {
                startDate: '2023-04-26',
                duration: 1
            },
            {
                startDate: '2023-05-03',
                duration: 1
            }
        ],
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
    }
]


const Trainings: React.FC = () => {
    const dispatch = useAppDispatch();
    const trainingState = useAppSelector((state: RootState) => state.training);
    const [availableDateRanges, setAvailableDateRanges] = useState<string[][]>([]);
    const [selectedRange, setSelectedRange] = useState<string[]>([]);
    const [isSelectingDate, setIsSelectingDate] = useState<boolean>(false);
    const datetimePickerRef = useRef<null | HTMLIonDatetimeElement>(null);
    const scrollDivRef = useRef<null | HTMLDivElement>(null);
    const validationButtonRef = useRef<null | HTMLIonButtonElement>(null);

    const isBtnDisabled: boolean = isSelectingDate && selectedRange.length === 0;

    useEffect(() => {
        if (!datetimePickerRef.current) return;

        if (selectedRange.length > 0) {
            datetimePickerRef.current.value = selectedRange;
        }
    }, [selectedRange]);

    useEffect(() => {
        if (isSelectingDate && scrollDivRef.current) {
            setTimeout(() => {
                // @ts-ignore
                scrollDivRef.current.scrollIntoView({behavior: 'smooth'});
            }, 200);
        }
    }, [isSelectingDate])

    useEffect(() => {
        if (selectedRange.length > 0 && validationButtonRef.current) {
            setTimeout(() => {
                // @ts-ignore
                validationButtonRef.current.scrollIntoView({behavior: 'smooth'});
            }, 200);
        }
    }, [selectedRange])


    useEffect(() => {
        let start: Date;

        const dateRanges: string[][] = [];

        if (trainingState.current) {
            for (let i in trainingState.current.trainingDates) {
                start = new Date(trainingState.current.trainingDates[i].startDate);
                dateRanges.push(toStrDateRange(getDateRange(start, trainingState.current.trainingDates[i].duration)));
            }

            setAvailableDateRanges(dateRanges);
        }
    }, [trainingState.current]);

    const onSelectTraining = (training: TrainingDetails) => {
        dispatch(trainingActions.onCurrentTrainingChange(training));
    }

    const computeTrainingClassNames = (index: number, totalCount: number): string => {
        let classNames: string = '';

        if (index + 1 === totalCount) {
            classNames = 'last-of-block '
        }

        const isEven: boolean = index % 2 == 0;
        if (isEven) {
            classNames += 'training-item-2'
        } else {
            classNames += 'training-item-1'
        }

        return classNames;
    }

    const computeCategoryBlock = (category: string) => {

        const categoryTrainingsCount: number = TRAININGS.filter(t => t.category === category).length;
        return <>
            <div className={'training-block'}>

                <IonHeader className={'training-block-header'} style={{padding: 10, fontWeight: 'bolder'}}>
                    {category}
                </IonHeader>
                {
                    TRAININGS.filter(t => t.category === category).map((t, i) => {

                        return <div key={i} className={computeTrainingClassNames(i, categoryTrainingsCount)}>
                            <IonGrid>
                                <IonRow>
                                    <IonCol className={'left-centered'}>
                                        {t.title}
                                    </IonCol>
                                    <IonCol size={'2'} style={{textAlign: 'right', opacity: 0.9}}>
                                        <IonIcon onClick={() => onSelectTraining(t)} icon={calendarOutline}/>
                                    </IonCol>
                                </IonRow>
                            </IonGrid>
                        </div>
                    })
                }

            </div>
            <br/>
        </>;
    }

    const computeCategoriesBlocks = () => {
        const categories: string[] = [...new Set(TRAININGS.map(t => t.category))];
        return categories.map((c, i) => {
            return <div key={i}>
                {computeCategoryBlock(c)}
            </div>
        });
    }

    const plusOneYear = (): Date => {
        const date = new Date();
        date.setFullYear(date.getFullYear() + 1);
        return date;
    }

    const getDateRange = (startDate: Date, rangeSize: number): Date[] => {
        const dateRange: Date[] = [];
        let otherDate: Date = new Date(startDate);

        for (let i = 0; i < rangeSize; i++) {
            otherDate = new Date(startDate);
            otherDate.setDate(startDate.getDate() + i)
            dateRange.push(otherDate)
        }

        return dateRange;
    }

    const toStrDateRange = (range: Date[]): string[] => {
        const strRange: string[] = [];
        range.forEach(d => {
            strRange.push(d.toISOString().slice(0, 10));
        })
        return strRange;
    }

    const isTrainingOpen = (dateString: string): boolean => {

        const date: Date = new Date(dateString);
        let openDate: Date;

        if (availableDateRanges.length > 0) {
            for (let i = 0; i < availableDateRanges.length; i++) {
                for (let j = 0; j < availableDateRanges[i].length; j++) {
                    openDate = new Date(availableDateRanges[i][j]);
                    if (date.getUTCDate() === openDate.getUTCDate()
                        && date.getUTCMonth() === openDate.getUTCMonth()
                        && date.getUTCFullYear() === openDate.getUTCFullYear()
                    ) {
                        return true;
                    }
                }
            }
        }

        return false;
    }

    const onValidateAction = () => {

        if (isSelectingDate) {
            console.debug('on va envoyer un message et faire des trucs')
        } else {
            setIsSelectingDate(true);
        }

    }

    const retrieveCorrespondingRange = (date: string): string[] => {
        for (let i = 0; i < availableDateRanges.length; i++) {
            if (availableDateRanges[i].includes(date)) {
                return availableDateRanges[i];
            }
        }
        return [];
    }

    const onSelectDate = (evt: CustomEvent) => {
        if (evt.detail.value && evt.detail.value.length && evt.detail.value.length > 0) {
            const inputDate: string = evt.detail.value[evt.detail.value.length - 1];
            const correspondingRange: string[] = retrieveCorrespondingRange(inputDate);
            setSelectedRange(correspondingRange);
        }
    }

    const computeContent = () => {
        if (trainingState.current) {
            return <>
                <div id={'scrollDiv'} className={'scrollDivRef'} ref={scrollDivRef}/>

                <IonGrid>
                    <IonRow>
                        <IonCol className={"centered"}>
                            <IonLabel className={'training-title'}>
                                {trainingState.current.category}
                            </IonLabel>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol className={"centered"}>

                            <IonLabel className={'training-sub-title'}>
                                {trainingState.current.title}
                            </IonLabel>
                        </IonCol>
                    </IonRow>
                </IonGrid>
                <IonGrid>
                    {
                        isSelectingDate ?
                            <>
                                <br/>
                                <IonRow>
                                    <IonCol>
                                        <IonDatetime
                                            ref={datetimePickerRef}
                                            id={"datetime"}
                                            presentation={'date'}
                                            showDefaultButtons={false}
                                            min={new Date().toISOString()}
                                            max={plusOneYear().toISOString()}
                                            isDateEnabled={isTrainingOpen}
                                            onIonChange={onSelectDate}
                                            multiple={true}
                                        />
                                    </IonCol>
                                </IonRow>
                            </> :
                            <IonRow>
                                <IonCol style={{padding: '25px'}}>
                                    <IonLabel>
                                        {trainingState.current.description}
                                    </IonLabel>
                                </IonCol>
                            </IonRow>
                    }

                </IonGrid>
                <div className={isSelectingDate ? 'validate-date-button' : 'reservation-button'}>
                    <IonButton
                        ref={validationButtonRef}
                        expand="block"
                        shape="round"
                        disabled={isBtnDisabled}
                        onClick={() => {
                            onValidateAction()
                        }}
                    >
                        {isSelectingDate ? 'Valider' : 'Choisir une date'}
                    </IonButton>
                </div>
            </>
        }

        return <>
            <IonGrid>
                <IonRow>
                    <p className={'paragraph'}>Pour assurer votre montée en compétences, vous pouvez demander à
                        votre iPNC de participer aux formations suivantes: </p>
                </IonRow>
            </IonGrid>
            <div style={{
                height: h,
                overflowY: "scroll"
            }}>
                {computeCategoriesBlocks()}
            </div>
        </>;
    }

    const h = window.innerHeight - (260);

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <>
                        {
                            trainingState.current ?
                                <div className={'previous-button'}>
                                    <div onClick={() => {
                                        setSelectedRange([]);
                                        setIsSelectingDate(false)
                                        dispatch(trainingActions.onCurrentTrainingChange(undefined));
                                    }}>
                                        <IonIcon icon={arrowBackOutline}/>
                                    </div>

                                </div>
                                :
                                <div/>
                        }
                        <IonImg style={{margin: '0 auto'}} src={'assets/images/bandeau.png'}/>
                    </>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                {computeContent()}
            </IonContent>
        </IonPage>
    );
};

export default Trainings;
