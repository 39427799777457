import Draggable, {DraggableData, DraggableEvent} from "react-draggable";
import {PersonChip} from "../PersonChip";
import React from "react";

export interface DraggablePncProps {
    pnc: string;
    isSelected: boolean;
    onSelect: (trigram: string) => void
    onDragStop: (trigram: string, rect: DOMRect) => void
}

export const DraggablePnc = (props: DraggablePncProps) => {
    const nodeRef = React.useRef<HTMLDivElement>(null);

    const onDragStart = (event: DraggableEvent, data: DraggableData) => {
        props.onSelect(props.pnc);
    }

    const onDrag = (event: DraggableEvent, position: DraggableData) => {
    }

    const onDragStop = (event: DraggableEvent, data: DraggableData) => {
        const element = nodeRef.current;
        if (element) {
            const rect = element.getBoundingClientRect();
            props.onDragStop(props.pnc, rect)
        }
    }

    return <Draggable nodeRef={nodeRef} key={props.pnc}
                      onDrag={onDrag}
                      onStart={onDragStart}
                      onStop={onDragStop}
    >
        <div ref={nodeRef}>
            <PersonChip
                isDisabled={false}
                trigram={props.pnc}
                isSelected={props.isSelected}
                onSelect={() => {
                    props.onSelect(props.pnc);
                }}
            />
        </div>
    </Draggable>
}
