import {IonChip, IonCol, IonGrid, IonIcon, IonRow} from "@ionic/react";
import './PersonChip.css';
import {checkmarkDoneOutline, personCircleOutline} from "ionicons/icons";

export interface PersonChipProps {
    trigram: string
    isSelected: boolean
    isDisabled: boolean,
    isDone?: boolean,
    onSelect: (trigram: string) => void
}

export const PersonChip = (props: PersonChipProps) => {
    let pncClassName: string = '';
    if (props.isSelected) {
        pncClassName = 'pnc-item-selected'
    } else {
        pncClassName = 'pnc-item'
    }
    return <div key={props.trigram}
                className={pncClassName}
                onClick={() => {
                    if(!props.isDone){
                        props.onSelect(props.trigram);
                    }
//                    setCurrentPnc(pnc)
                }}
    >
        <IonGrid className={'no-padding'}>
            <IonRow className={'no-padding '}>
                <IonCol className={'no-padding centered'}>
                    <IonIcon icon={personCircleOutline}/>
                </IonCol>
            </IonRow>
            <IonRow className={'no-padding'}>
                <IonCol className={'no-padding centered'}>
                    {props.trigram}
                    {
                        //evaluatedPncs.includes(pnc)
                        props.isDone
                            ? <IonIcon size={'small'} icon={checkmarkDoneOutline}/>
                            :
                            <div/>
                    }
                </IonCol>
            </IonRow>
        </IonGrid>
    </div>


}
