import {IonContent, IonGrid, IonHeader, IonIcon, IonImg, IonList, IonPage, IonToolbar} from '@ionic/react';
import './Flight.css';
import React, {useEffect, useState} from "react";
import {RootState} from "../app/store";
import {useAppDispatch, useAppSelector} from "../app/hooks";
import {getFlightsEvaluations} from "../helpers/backendApiHelper";
import {flightActions} from "../features/flight/flightSlice";

import {refreshToken} from "../helpers/authApiHelper";
import {authActions} from "../features/authentication/authSlice";
import {FlightItem} from "../components/flights/FlightItem";
import {arrowBackOutline} from "ionicons/icons";
import './FlightScreen.css';
import {CurrentFlight} from "../components/CurrentFlight";
import {FlightEvaluation} from "../model";
import {CustomSpinner} from "../components/CustomSpinner";
import {CcpFlightScreen} from "./CcpFlightScreen";
import {useTranslation} from "react-i18next";

export const FlightScreen: React.FC = () => {
    const dispatch = useAppDispatch();
    const auth = useAppSelector((state: RootState) => state.auth);
    const flight = useAppSelector((state: RootState) => state.flight);
    const user = useAppSelector((state: RootState) => state.user);
    const [hasFetchFlights, setHasFetchFlights] = useState<boolean>(false);
    const [isFetchingFlights, setIsFetchingFlights] = useState<boolean>(true);
    const [isUserCcp, setIsUserCcp] = useState<boolean>(false);
    const {t} = useTranslation(['pages.flights', 'common']);

    useEffect(() => {
        const fetchFlights = async () => {
            refreshToken(auth.accessToken, auth.refreshToken)
                .then((authResponse) => {
                    getFlightsEvaluations(
                        {accessToken: authResponse.accessToken},
                        //@ts-ignore
                        user.details,
                        () => dispatch(authActions.onShouldAuthenticateChange(true)) //could be replaced by a .catch()
                    )
                        .then((flights: FlightEvaluation[]) => {
                            dispatch(flightActions.onFlightsChange(flights));
                            setIsFetchingFlights(false);
                        })
                        .catch((e) => console.error("Unable to get flights evaluations: ", e))
                })
                .catch(() => {
                    dispatch(authActions.onShouldAuthenticateChange(true));
                })
        }

        if (user.details && !hasFetchFlights) {
            if (user.details.roles.includes('ccp')) {
                setIsUserCcp(true);
            } else {
                setIsUserCcp(false);
                fetchFlights().then(() => {
                    setHasFetchFlights(true);
                }).catch((e) => console.error("Unable to fetch flights: ", e))
            }
        }

    }, [hasFetchFlights]); //-> [] ?

    const computeContent = () => {
        if (isUserCcp) {
            return <CcpFlightScreen/>;
        }

        if (isFetchingFlights) {
            return <CustomSpinner>{t('fetchingFlights')}</CustomSpinner>;
        }

        if (flight.current) {
            return <CurrentFlight/>
        }

        return <IonContent>
            <IonGrid>
                <IonList>
                    {
                        flight.flights.map((f, i) => {
                            return <FlightItem key={i} item={f}/>
                        })
                    }
                </IonList>
            </IonGrid>
        </IonContent>;
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar className={'new-background-color'}>
                    <>
                        {
                            flight.current && flight.current.flightReference !== '' ?

                                <div className={'previous-button'}>
                                    <div onClick={() => {
                                        dispatch(flightActions.onFillSurvey([]));
                                        dispatch(flightActions.onCurrentFlightChange(undefined));
                                        dispatch(flightActions.onCurrentFlightWeaknessChange(undefined));
                                        setHasFetchFlights(false);
                                    }}>
                                        <IonIcon icon={arrowBackOutline}/>
                                    </div>

                                </div>
                                :
                                <div/>
                        }
                        <IonImg style={{margin: '0 auto', height: 108}} src={'assets/images/logo_crewsbest.png'}/>
                    </>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                {computeContent()}
            </IonContent>
        </IonPage>
    );
};
//<IonImg className={"displayed"} src={'assets/images/bandeau.png'}/>
