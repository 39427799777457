import {
    InputChangeEventDetail,
    IonButton,
    IonCol,
    IonGrid,
    IonIcon,
    IonImg,
    IonInput,
    IonLabel,
    IonRow
} from "@ionic/react";
import React, {useState} from "react";
import './LoginScreen.css';
import {atOutline, keyOutline, personOutline} from "ionicons/icons";
import {authenticateWithCode, sendValidationCodeTo} from "../../helpers/authApiHelper";
import {handleResponse, isCleanEmailInput, isCleanValidationCodeInput, isEmailValid} from "../../utils/utils";
import {useAppDispatch} from "../../app/hooks";
import {authActions} from "../../features/authentication/authSlice";
import {getMe} from "../../helpers/backendApiHelper";
import {userActions} from "../../features/user/userSlice";
import {useTranslation} from "react-i18next";

//password less login screen
export const LoginScreen = () => {
    const dispatch = useAppDispatch();
    const [userEmail, setUserEmail] = useState<string>('');
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [validationCode, setValidationCode] = useState<string>('');
    const [hasSentCode, setHasSentCode] = useState<boolean>(false);
    const {t} = useTranslation(['pages.login', 'common']);

    const sanitizeEmailInputEvent = (event: any) => {
        let newValue = event.target.value;
        if (!isCleanEmailInput(newValue)) {
            event.target.value = newValue.slice(0, -1);
        }
    }

    const sanitizeEmailInput = (detail: InputChangeEventDetail) => {
        if (!detail.value) {
            return;
        }

        if (isCleanEmailInput(detail.value!)) {
            setUserEmail(detail.value!);
        }
    }

    const sanitizeValidationCodeInputEvent = (event: any) => {
        let newValue = event.target.value;
        if (!isCleanValidationCodeInput(newValue)) {
            event.target.value = newValue.slice(0, -1);
        }
    }

    const sanitizeValidationCodeInput = (detail: InputChangeEventDetail) => {
        if (!detail.value) {
            return;
        }

        if (isCleanValidationCodeInput(detail.value!)) {
            setValidationCode(detail.value!);
        }
    }

    const startAuthentication = () => {
        setErrorMessage('');
        sendValidationCodeTo(userEmail)
            .then(r => {
                handleResponse(r,
                    () => {
                        setHasSentCode(true);
                    },
                    (errorMessage => {
                        setErrorMessage(errorMessage);
                    }));
            }).catch(e => {
            console.warn('error: ', e)
        });
    }

    const validateCode = () => {
        setErrorMessage('');

        authenticateWithCode(userEmail, validationCode)
            .then(rsp => {
                handleResponse(rsp,
                    (body) => {
                    console.debug('<<< auth OK')
                        if (body.accessToken) {
                            dispatch(authActions.onAccessTokenChange(body.accessToken));
                        }
                        if (body.refreshToken) {
                            dispatch(authActions.onRefreshTokenChange(body.refreshToken));
                        }
                        getMe({accessToken: body.accessToken}, () => console.error("Unable to retrieve user details"))
                            .then((p) => {
                                p && dispatch(userActions.onUserDetailsChange(p));
                            })
                            .catch(e => {
                                console.warn('error: ', e)
                            })
                    },
                    (errorMessage => {
                        setErrorMessage(errorMessage);
                    }));
            }).catch(e => {
            console.warn('error: ', e)
        });

    }

    const enterEmailBlock = () => {
        let isButtonDisabled: boolean = true;
        if (isEmailValid(userEmail)) {
            isButtonDisabled = false;
        }

        return <>
            <IonRow className={'centered'}>
                <IonCol size={'2'}>
                    <IonLabel><IonIcon icon={atOutline}/></IonLabel>
                </IonCol>
                <IonCol size={'10'}>
                    <IonInput
                        key={'email-input'}
                        value={userEmail}
                        placeholder={t('emailPlaceholder')}
                        onKeyUp={e => sanitizeEmailInputEvent(e)}
                        onIonChange={e => {
                            sanitizeEmailInput(e.detail);
                        }}
                        minlength={1}
                        maxlength={32}
                    />
                </IonCol>
            </IonRow>
            <IonRow className={'error-message-row'}>
                <IonCol className={'centered'}>
                    {errorMessage}
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol className={'centered'}>
                    <IonButton
                        disabled={isButtonDisabled}
                        onClick={startAuthentication}
                        expand="block"
                        shape="round"
                    >
                        {t('common:buttons.validate')}
                    </IonButton>
                </IonCol>
            </IonRow>
        </>;
    }

    const validateCodeBlock = () => {
        let isButtonDisabled: boolean = true;
        if (validationCode.replace(' ', '').length === 6) {
            isButtonDisabled = false;
        }

        return <>
            <IonRow className={'centered'}>
                <IonCol size={'2'}>
                    <IonLabel>
                        <IonLabel><IonIcon icon={keyOutline}/></IonLabel>
                    </IonLabel>
                </IonCol>
                <IonCol size={'10'}>
                    <IonInput
                        key={'validation-code-input'}
                        value={validationCode}
                        placeholder={t('enterCodePlaceHolder')}
                        onKeyUp={e => sanitizeValidationCodeInputEvent(e)}
                        onIonChange={e => {
                            sanitizeValidationCodeInput(e.detail);
                        }}
                        minlength={1}
                        maxlength={10}
                    />
                </IonCol>
            </IonRow>
            <IonRow className={'error-message-row'}>
                <IonCol className={'centered'}>
                    {errorMessage}
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol className={'centered'}>
                    <IonButton
                        disabled={isButtonDisabled}
                        onClick={validateCode}
                        expand="block"
                        shape="round"
                    >
                        {t('common:buttons.validate')}
                    </IonButton>
                </IonCol>
            </IonRow>
            <IonRow>

                <IonCol className={'centered'} size={'6'}>
                    <IonButton className={'nav-link'} fill="clear" onClick={() => {
                        setUserEmail('');
                        setValidationCode('');
                        setHasSentCode(false);
                    }}>
                        {t('modifyEmail')}
                    </IonButton>
                </IonCol>
                <IonCol className={'centered'} size={'6'}>
                    <IonButton className={'nav-link'} fill="clear" onClick={() => {
                        setErrorMessage('');
                        setValidationCode('');
                        startAuthentication();
                    }}>
                        {t('resendCode')}
                    </IonButton>
                </IonCol>
            </IonRow>
        </>
    }

    return <>
        <div className={'logo'}>
            <IonImg style={{margin: '0 auto', height: 108}} src={'assets/images/logo_crewsbest.png'}/>
        </div>
        <div className={"middle-screen-block"}>
            <IonGrid>
                <IonRow>
                    <IonCol className={'centered'}>
                        <IonIcon icon={personOutline}/>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol className={'centered'}>
                        <IonLabel className={'title'}>{t('title')}</IonLabel>
                    </IonCol>
                </IonRow>
                {hasSentCode ? validateCodeBlock() : enterEmailBlock()}
            </IonGrid>
        </div>
    </>
}
