export interface AppConfiguration {
    //consumerId: string;
    backendUrl: string;
}

const DEV_CONFIGURATION: AppConfiguration = {
    //consumerId: 'AIR_AUSTRAL',
    //backendUrl: 'http://localhost:9000'
    backendUrl: 'http://192.168.1.4:9000'
}
/*
const EMULATOR_TEST_CONFIGURATION: AppConfiguration = {
    consumerId: 'SOME_CUSTOMER_REF',
    backendUrl: 'http://10.0.2.2:9000'
}
*/

const DEV_INT_CONFIGURATION: AppConfiguration = {
    //consumerId: 'AIR_AUSTRAL',
    //backendUrl: 'http://10.0.0.4:9100'
    backendUrl: 'https://test.backend.ksystem.io'
}


const PROD_CONFIGURATION: AppConfiguration = {
    backendUrl: 'https://api.air-austral.smart-evaluator.com'
}
export const CONFIGURATION: AppConfiguration = PROD_CONFIGURATION;
