import {FlightEvaluation, FlightInstanceDto, PersonDto} from "../model";
import * as jose from "jose";

export const sortByDate = (previous: FlightEvaluation[], oldestFirst?: boolean): FlightEvaluation[] => {
    if (oldestFirst) {
        return previous
            .slice()
            .sort(
                (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
            )
    }
    return previous
        .slice()
        .sort(
            (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
        )
}

export const sortFlightInstanceByDate = (previous: FlightInstanceDto[], oldestFirst?: boolean): FlightInstanceDto[] => {
    if (oldestFirst) {
        return previous
            .slice()
            .sort(
                (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
            )
    }
    return previous
        .slice()
        .sort(
            (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
        )
}

export const findPreviousFlightEvaluation = (currentFlight: FlightEvaluation, previousEvaluations: FlightEvaluation[]): FlightEvaluation | undefined => {
    previousEvaluations = sortByDate(previousEvaluations);
    const currentIndex: number = previousEvaluations.findIndex(f => f.flightInstanceId === currentFlight.flightInstanceId);
    //last item
    if (currentIndex === previousEvaluations.length - 1) {
        return undefined;
    }

    if (currentIndex >= 0) {
        return previousEvaluations[currentIndex + 1]
    }

    if (currentIndex < 0) {
        return previousEvaluations.length > 0 ? previousEvaluations[0] : undefined;
    }

    return undefined;
}


export const formatDateWithSlashes = (date: string) => {
    const dDate = new Date(date);

    const day: number = dDate.getDate();
    const strDay: string = day < 10 ? '0' + day.toString() : day.toString();
    const month: number = (dDate.getMonth().valueOf() + 1);
    const strMonth: string = month < 10 ? '0' + month.toString() : month.toString();
    const strYear: string = dDate.getFullYear().toString().slice(2, 4);

    return strDay + '-' + strMonth + '-' + strYear;
}
/*
export const computeTotal = (previousEvaluations: FlightEvaluation[], currentEvaluation: Evaluation | undefined): number => {
    const prevTotals: number[] = previousEvaluations.map(pe => pe.evaluation.manager + pe.evaluation.self + pe.evaluation.bonus);
    let prevTotal: number = 0;
    prevTotals.forEach(n => prevTotal += n);

    if (!currentEvaluation) {
        return prevTotal;
    }

    return prevTotal + currentEvaluation.self + currentEvaluation.bonus + currentEvaluation.manager;
}
*/
export const computeTotal = (flightEvaluations: FlightEvaluation[]): number => {
    return sum(flightEvaluations.flatMap(fe => fe.totalScore));
}

export const CLEAN_EMAIL_INPUT_REGEX: string = '^[a-zA-Z0-9\\ \\_\\-\\.\\@]{1,}$';
export const CLEAN_CODE_INPUT_REGEX: string = '^[0-9\\ ]{1,}$';


export const isInputClean = (input: string, regex: string): boolean => {
    return new RegExp(regex).test(input)
}

export const isCleanEmailInput = (input: string): boolean => {
    return isInputClean(input, CLEAN_EMAIL_INPUT_REGEX);
}

export const isCleanValidationCodeInput = (input: string): boolean => {
    return isInputClean(input, CLEAN_CODE_INPUT_REGEX);
}

export const isEmailValid = (inputEmail: string): boolean => {
    return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(inputEmail);
}

export const UNEXPECTED_ERROR_OCCURRED = 'Une erreur innatendue est survenue. Veuillez réessayer plus tard.'

export const handleResponse = (response: any, onSuccess: (body: any) => void, onError: (reasonMessage: string) => void) => {
    console.debug(' --- handleResponse --- ', response)
    if (response.statusCode && response.statusCode === 200) {
        onSuccess(response.body);
    }

    if (response.statusCode && response.statusCode !== 200) {
        if (response.message) {
            onError(response.message);
        } else {
            onError(UNEXPECTED_ERROR_OCCURRED);
        }
    }
}

export const extractRolesFrom = (jwt: string): string[] => {
    const jwtPayload = jose.decodeJwt(jwt);
    if (jwtPayload && jwtPayload.roles) {
        // @ts-ignore
        return jwtPayload.roles;
    }
    return [];
}

export const toUnixTimestamp = (date: Date): number => {
    return Math.floor(date.getTime() / 1000)
}

//to be changed by has been evaluated or something like that
export const isOldFlight = (flight: FlightEvaluation): boolean => {
    const yesterday: Date = (d => new Date(d.setDate(d.getDate() - 1)))(new Date);
    return toUnixTimestamp(new Date(flight.date)) <= toUnixTimestamp(yesterday);
}

export const hasBeenFullyEvaluated = (flight: FlightEvaluation): boolean => {
    return flight.hasBeenAutoEvaluated && flight.hasBeenEvaluated;
}

export const shouldDisplayFlight = (flight: FlightEvaluation): boolean => {
    return (isOldFlight(flight) || hasBeenFullyEvaluated(flight));
}

export const hasFilledSurvey = (flightState: any, surveyState: any): boolean => {
    if(flightState.current && flightState.current.hasBeenAutoEvaluated) {
        return true;
    }
    if (flightState.currentSurvey) {
        return surveyState.surveysFilledAsPnc.includes(flightState.currentSurvey.id);
    }
    return false;
}

export const filterOldFlights = (flights: FlightEvaluation[]): FlightEvaluation[] => {
    return flights.filter(f => isOldFlight(f));
}

export const shouldDisplayBottomToolBar = (user: PersonDto | undefined): boolean => {
    if (!user) {
        return false;
    }
    return !user.roles.includes('ccp');
}

export const isCcp = (user: PersonDto | undefined): boolean => {
    if (!user) {
        return false;
    }
    return user.roles.includes('ccp');
}

export const sum = (values: number[]): number => {
    let sumResult: number = 0;
    for (let index in values) {
        sumResult += values[index];
    }
    return sumResult;
}
