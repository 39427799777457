import {namespaces} from "./i18n.constants";

export const fr = {
    [namespaces.common]: {
        buttons: {
            validate: "Valider",
            save: "Enregistrer",
        },
        menu: {
            flights: "Vols",
            points: "Points",
            trainings: "Formations"
        },
        survey: {
            categories: {
                briefing: "Briefing - AVANT VOL",
                preparation: "Préparation de l'appareil - PREVOL",
                boarding: "Embarquement des passagers - ACCUEIL",
                inFlight: "En vol - SERVICE À BORD"
            }
        }
    },
    [namespaces.pages.login]: {
        title: "Identifiez-vous",
        emailPlaceholder: "Saisissez votre email",
        enterCodePlaceHolder: "Saisissez le code reçu par mail",
        modifyEmail: "Modifier l'email",
        resendCode: "Renvoyer le code"
    },
    [namespaces.pages.flights]: {
        searchingFlight: "Recherche de vol",
        fetchingFlights: "Récupération des vols",
        fetchingFlightDetails: "Récupération des détails du vol",
        noCcpFlight: "Aucun vol à évaluer ou à venir.",
        postsAssignment: {
            title: "Attribution des postes",
            description: "Sélectionnez un PNC, puis cliquez sur le poste",
            positionsProvided: "Tous les postes sont pourvus",
            positionsSaved: "Les postes ont été enregistrés. Bon vol !"
        },
        evaluation: {
            selectPnc: "Sélectionnez un PNC à évaluer",
            allSaved: "Evaluation des PNC complète",
            pncDone1: "Evaluation de",
            pncDone2: "enregistrée",
            answers: {
                std_to_learn: "A acquérir",
                std_to_be_improved: "A améliorer",
                std_compliant: "Conforme"
            },
            callToValidate: "Valider le formulaire",
            pncCompleted: "PNC, merci d'avoir rempli le formulaire, un e-mail vous sera envoyé lorsque l'évaluation sera disponible."
        },
        surveyReport: {
            likePrevious: "Comme le précédent vol",
            sincePrevious: "depuis le précédent vol",
            weaknessMessage: "Voici la liste des items non conformes sur lesquels vous pouvez vous améliorer :"
        }
    }
};

export const en = {
    [namespaces.common]: {
        buttons: {
            validate: "Validate",
            save: "Save",
        },
        menu: {
            flights: "Flights",
            points: "Points",
            trainings: "Trainings"
        },
        survey: {
            categories: {
                briefing: "Briefing - BEFORE FLIGHT",
                preparation: "Preparing the plane - PRE-FLIGHT",
                boarding: "Passenger boarding - WELCOME",
                inFlight: "In flight - ON BOARD SERVICE"
            }
        }
    },
    [namespaces.pages.login]: {
        title: "Identify yourself",
        emailPlaceholder: "Enter your email",
        enterCodePlaceHolder: "Enter the code received by email",
        modifyEmail: "Edit email",
        resendCode: "Send the code again"
    },
    [namespaces.pages.flights]: {
        searchingFlight: "Searching for flights",
        fetchingFlights: "Retrieving flights",
        fetchingFlightDetails: "Retrieving flight details",
        noCcpFlight: "No flights to evaluate or to come.",
        postsAssignment: {
            title: "Allocation of positions",
            description: "Select a cabin crew member then click on the position",
            positionsProvided: "All positions provided",
            positionsSaved: "The positions have been saved. Have a good flight !"
        },
        evaluation: {
            selectPnc: "Select a cabin crew member to evaluate",
            allSaved: "All cabin crew members assessment now completed",
            pncDone1: "Rating of",
            pncDone2: "recorded",
            answers: {
                std_to_learn: "To acquire",
                std_to_be_improved: "To improve",
                std_compliant: "Compliant"
            },
            callToValidate: "Validate the form",
            pncCompleted: "PNC, thank you for completing the form, an email will be sent to you when the evaluation is available."
        },
        surveyReport: {
            likePrevious: "Like previous flight",
            sincePrevious: "since previous flight",
            weaknessMessage: "Here is the list of non-compliant items on which you can improve :"
        }
    }
};
