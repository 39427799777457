import {IonIcon} from "@ionic/react";
import React, {useEffect} from "react";
import {PersonChip} from "../PersonChip";
import {closeOutline} from "ionicons/icons";

export interface PostAssignmentProps {
    post: string
    pnc: string | undefined
    onCancel: (pnc: string | undefined, post: string) => void
    onAssignPost: () => void
    onDraw: (post: string, position: DOMRect) => void
    readonly?: boolean
}

export const PostAssignment = (props: PostAssignmentProps) => {
    const ref = React.useRef<HTMLDivElement>(null);

    useEffect(() => {
        const element = ref.current;
        if (element) {
            const rect = element.getBoundingClientRect();
            props.onDraw(props.post, rect)
        }
    }, [])

    const buildCloseButtonRowContent = () => {
        if (props.readonly) {
            return <div className={'drop-area-row draggable-container'} style={{textAlign: 'right', opacity: 0}}>
                <IonIcon size={'small'} icon={closeOutline}/>
            </div>
        }
        return <div className={'drop-area-row'} style={{textAlign: 'right', opacity: props.pnc ? 0.5 : 0}}>
            <IonIcon size={'small'} onClick={() => props.onCancel(props.pnc, props.post)} icon={closeOutline}/>
        </div>
    }

    const buildPostPersonChip = () => {
        return <div className={'drop-area-row'} style={{opacity: props.pnc ? 1 : 0}}>
            <PersonChip isDisabled={true} trigram={props.pnc ? props.pnc : ''} isSelected={false} onSelect={() => {
            }}/>
        </div>
    }

    return <div className={"drop-area"} ref={ref} onClick={props.onAssignPost}>
        {buildCloseButtonRowContent()}
        <div className={'drop-area-row'}><b>{props.post}</b></div>
        {buildPostPersonChip()}
    </div>;
}
