import {IonContent, IonHeader, IonImg, IonPage, IonToolbar} from '@ionic/react';
import './Points.css';
import React from "react";
import {PointsChart} from "../components/PointsChart";

const Points: React.FC = () => {

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar className={'new-background-color'}>
                    <>
                        <IonImg style={{margin: '0 auto', height: 108}} src={'assets/images/logo_crewsbest.png'}/>
                    </>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <PointsChart/>
            </IonContent>
        </IonPage>
    );
};

export default Points;
