import React from "react";
import {IonCol, IonContent, IonGrid, IonRow, IonSpinner} from "@ionic/react";
import {WaitingDots} from "./WaitingDots";
import './CustomSpinner.css'

export interface CustomSpinnerProps {
    children?: React.ReactNode;
}

export const CustomSpinner = (props: CustomSpinnerProps) => {
    return <IonContent>
        <div className={'middle-content centered'}>
            <IonGrid>
                <IonRow>
                    <IonCol>
                        <IonSpinner/>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <IonGrid>
                            <IonRow>
                                <IonCol size={'8'}>
                                    <div className={'sentence'}>
                                        {props.children && props.children}
                                    </div>
                                </IonCol>
                                <IonCol className={'dots'} size={'4'}>
                                    <WaitingDots/>
                                </IonCol>
                            </IonRow>

                        </IonGrid>
                    </IonCol>
                </IonRow>
            </IonGrid>
        </div>
    </IonContent>;
}
