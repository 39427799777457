import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {extractRolesFrom} from "../../utils/utils";
import {getPreferences, setPreferences} from "../../services/storage.service";

//import {callRefreshAccessToken} from "../../helpers/authApiHelper";
const KEY: string = 'auth';

export interface authState {
    shouldAuthenticate: boolean;
    accessToken: string;
    refreshToken: string;
    roles: string[]
}

const initialState: authState = {
    shouldAuthenticate: true,
    accessToken: '',
    refreshToken: '',
    roles: []
}

export const loadStoredPreferences = createAsyncThunk(
    'auth/loadStoredPreferences',
    async () => {
        return await getPreferences<authState>(KEY, initialState)
            .then((result) => {
                return result;
            });
    }
)

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        onLoadPreferences: (state, action: PayloadAction<authState>) => {
            const storedState: authState = action.payload;
            state.roles = storedState.roles;
            state.shouldAuthenticate = storedState.shouldAuthenticate
            state.refreshToken = storedState.refreshToken
            state.accessToken = storedState.accessToken
        },
        onAccessTokenChange: (state, action: PayloadAction<string>) => {
            state.accessToken = action.payload;
            state.shouldAuthenticate = false;
            state.roles = extractRolesFrom(action.payload);
        },
        onRefreshTokenChange: (state, action: PayloadAction<string>) => {
            state.refreshToken = action.payload;
            setPreferences(KEY, state).then();
        },
        onShouldAuthenticateChange: (state, action: PayloadAction<boolean>) => {
            state.shouldAuthenticate = action.payload;
        }
    },
    extraReducers: (builder) => {
        // Add reducers for additional action types here, and handle loading state as needed
        builder
            .addCase(loadStoredPreferences.fulfilled, () => {
            })
    }
})

export const authActions = authSlice.actions

export default authSlice.reducer
